// Libraries
import React from "react";
import {get} from "lodash";
import {connect} from "react-redux";
import {compose} from "recompose";
import {Link} from "react-router-dom";

// Components
import Page from "components/Page";
import {H1} from "components/Typography";
import Button from "components/Button/Button";
import Table from "components/Table";

// Styles
import styles from './List.module.scss';

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';
import useList from "./hooks";

type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string
  }
};

const COLUMNS = [
  {
    Header: 'ФИО контакта',
    accessor: 'fio',
  },
  {
    Header: 'Кол-во детей',
    accessor: 'childrenCount',
  },
  {
    Header: 'Дата изменения',
    accessor: 'updated_at',
  },
  {
    Header: 'Открытых дел',
    accessor: 'countOfOpening',
  },
  {
    Header: 'Действия',
    accessor: 'actions',
  },
];


const List = ({ session, match }: LIST_PROPS_TYPE): JSX.Element => {
  const { url } = match;
  const usedList = useList({ session, url });
  const {
    loading,
    data,
  } = usedList;

  return (
    <Page loading={loading}>
      <H1>
        Карточки семей
      </H1>
      <Table
        columns={COLUMNS}
        data={data || []}
        additional={{
          toolbar: (
            <Button
              component={Link}
              href={`${url}/new`}
              className={styles.Button}
            >
              Создать
            </Button>
          )
        }}
      />
    </Page>
  )
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

export default compose<LIST_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(List);
