
// Components
import Page from 'components/Page';
import {get} from "lodash";
import {ACTION_TYPE, SESSION_TYPE} from "../../../services/session";
import {compose} from "recompose";
import {connect} from "react-redux";
import React from "react";
import {PATHS_MAP} from "../../Users/User/data";
import {UserContext, useUser} from "../../Users/User/hooks";
import classNames from "classnames";
import styles from "../../Users/User/User.module.scss";
import Title from "../../Users/User/components/Title";
import Wizard from "./Wizard";
import { CaseContext, useCase } from './hooks';


type CASE_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string,
    path: string,
  }
};

const Case = ({
  session,
  match,
}: CASE_PROPS_TYPE): React.ReactElement<typeof Page> => {
  const { path } = match;
  const { role, title } = get(PATHS_MAP, `['${path.split('/:id')[0]}']`, PATHS_MAP['/users']);
  const usedCase = useCase({ session, role, title });
  const { loading } = usedCase;

  return (
    <Page
      classes={{
        root: classNames(styles.Root)
      }}
      loading={loading}
    >
      <div className={styles.Wrapper}>
        <CaseContext.Provider value={usedCase}>
          <Title className={styles.Title} />
          <div className={styles.Actions}>
            <Wizard />
          </div>
        </CaseContext.Provider>
      </div>
    </Page>
  )
};


// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<CASE_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Case);