// Libraries
import React from "react";
import {v4 as uuidv4} from "uuid";
import classNames from "classnames";
import { FieldArray } from 'redux-form';

// Components
import {H5} from "components/Typography";
import Paper from '@material-ui/core/Paper';
import {Checkbox, DatePicker, Input, Radio} from "components/Form";
import Box from "@material-ui/core/Box";
import {Button} from "components/Button";

// Styles
import styles from '../styles.module.scss';
import {Divider} from "@material-ui/core";

const GENDERS = [
  {
    id: uuidv4(),
    name: 'мужской',
    value: 'male'
  },
  {
    id: uuidv4(),
    name: 'женский',
    value: 'female'
  }
];

const familyMembers = (props: any): JSX.Element => {
  const { fields, meta : { error } } = props;

  return (
    <>
      {
        fields.map((familyMember: any, i: number): JSX.Element => (
          <Paper
            className={styles.FamilyMemberPaper}
            variant="outlined"
            key={i}
          >
            <Input
              className={styles.Input}
              key={`${familyMember}.surname`}
              label="Фамилия"
              name={`${familyMember}.surname`}
              placeholder="Фамилия"
              required
            />
            <Input
              className={styles.Input}
              key={`${familyMember}.name`}
              label="Имя"
              name={`${familyMember}.name`}
              placeholder="Имя"
            />
            <Input
              className={styles.Input}
              key={`${familyMember}.patronymic`}
              label="Отчество"
              name={`${familyMember}.patronymic`}
              placeholder="Отчество"
            />
            <Input
              className={styles.Input}
              key={`${familyMember}.phone`}
              label="Телефон"
              name={`${familyMember}.phone`}
              placeholder="Телефон"
            />
            <Input
              className={styles.Input}
              key={`${familyMember}.status`}
              label="Степень родства"
              name={`${familyMember}.status`}
              placeholder="Степень родства"
            />
            <Box className={styles.CheckboxContainer}>
              <Checkbox
                key={`${familyMember}.is_contact_face`}
                name={`${familyMember}.is_contact_face`}
                label="Контактное лицо"
              />
            </Box>
            <div className={styles.Divider}>
              <Divider />
            </div>
            <Input
              className={styles.Input}
              key={`${familyMember}.passport_series`}
              label="Серия паспорта"
              name={`${familyMember}.passport_series`}
              placeholder="Серия паспорта"
            />
            <Input
              className={styles.Input}
              key={`${familyMember}.passport_number`}
              label="Номер паспорта"
              name={`${familyMember}.passport_number`}
              placeholder="Номер паспорта"
            />
            <DatePicker
              className={styles.Input}
              key={`${familyMember}.passport_create`}
              label="Дата выдачи паспорта"
              name={`${familyMember}.passport_create`}
              placeholder="Дата выдачи паспорта"
            />
            <Input
              className={classNames(styles.Input, styles.WhoGetAddressField)}
              key={`${familyMember}.passport_subdivision`}
              label="Кем выдан"
              name={`${familyMember}.passport_subdivision`}
              placeholder="Кем выдан"
            />
            <Input
              className={styles.Input}
              key={`${familyMember}.passport_subdivision_code`}
              label="Код подразделения"
              name={`${familyMember}.passport_subdivision_code`}
              placeholder="Код подразделения"
            />
            <Input
              className={classNames(styles.Input, styles.AddressField)}
              key={`${familyMember}.position`}
              label="Фактический адрес"
              name={`${familyMember}.position`}
              placeholder="Фактический адрес"
            />
            <Input
              className={classNames(styles.Input, styles.AddressField)}
              key={`${familyMember}.address`}
              label="Адрес регистрации"
              name={`${familyMember}.address`}
              placeholder="Адрес регистрации"
            />
            <Button
              classes={{ root: styles.DeleteFamilyMember }}
              onClick={(): void => fields.remove(i)}
            >
              Удалить форму
            </Button>
          </Paper>
        ))
      }
      <div className={styles.Actions}>
        <Button
          classes={{ root: styles.AddFamilyMember }}
          onClick={(): void => fields.push()}
        >
          +
        </Button>
        Добавить члена семьи
      </div>
    </>
  )
};

const renderInfoAboutChildrenInFamily = ({ fields, meta: { error } }: any): JSX.Element => {
  return (
    <>
      {
        fields.map((child: any, i: number): JSX.Element => (
          <Paper
            className={styles.ChildrenPaper}
            variant="outlined"
            key={i}
          >
            <Input
              className={styles.Input}
              key={`${child}.surname`}
              label="Фамилия"
              name={`${child}.surname`}
              placeholder="Фамилия"
              required
            />
            <Input
              className={styles.Input}
              key={`${child}.name`}
              label="Имя"
              name={`${child}.name`}
              placeholder="Имя"
            />
            <Input
              className={styles.Input}
              key={`${child}.patronymic`}
              label="Отчество"
              name={`${child}.patronymic`}
              placeholder="Отчество"
            />
            <Input
              className={classNames(styles.Input, styles.Place)}
              key={`${child}.address`}
              label="Место нахождения"
              name={`${child}.address`}
              placeholder="Место нахождения"
            />
            <div className={styles.GenderField}>
              <Radio
                label="Пол"
                name={`${child}.gender`}
                options={GENDERS}
              />
            </div>
            <DatePicker
              className={styles.Input}
              key={`${child}.birthday`}
              label="Дата рождения"
              name={`${child}.birthday`}
              placeholder="Дата рождения"
            />
            <Input
              className={styles.Input}
              key={`${child}.document_type`}
              label="Тип документа"
              name={`${child}.document_type`}
              placeholder="Тип документа"
            />
            <DatePicker
              className={styles.Input}
              key={`${child}.document_create`}
              label="Дата выдачи документа"
              name={`${child}.document_create`}
              placeholder="Дата выдачи документа"
            />
            <Input
              className={styles.Input}
              key={`${child}.document_number`}
              label="Номер документа"
              name={`${child}.document_number`}
              placeholder="Номер документа"
            />
            <Input
              className={styles.Input}
              key={`${child}.document_subdivision_code`}
              label="Код подразделения"
              name={`${child}.document_subdivision_code`}
              placeholder="Код подразделения"
            />
            <Input
              className={classNames(styles.Input, styles.WhoGetAddressChildField)}
              key={`${child}.document_subdivision`}
              label="Кем выдан"
              name={`${child}.document_subdivision`}
              placeholder="Кем выдан"
            />
            <Button
              classes={{ root: styles.DeleteFamilyMember }}
              onClick={(): void => fields.remove(i)}
            >
              Удалить форму
            </Button>
          </Paper>
        ))
      }
      <div className={styles.Actions}>
        <Button
          classes={{ root: styles.AddFamilyMember }}
          onClick={(): void => fields.push()}
        >
          +
        </Button>
        Добавить ребёнка
      </div>
    </>
  )
};

const DATA = (): JSX.Element => {
  return (
    <div>
      <H5
        className={styles.H5}
      >
        Информация о членах семьи
      </H5>
      <FieldArray name='members' component={familyMembers} />
      <H5
        className={styles.H5}
      >
        Информация о детях в семье
      </H5>
      <FieldArray name='kids' component={renderInfoAboutChildrenInFamily} />
    </div>
  )
};

const MAIN = {
  id: uuidv4(),
  name: 'Данные семьи',
  data: DATA,
};

export default MAIN;
