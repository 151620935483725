// Types
import { MotorSphere as MotorSphereI } from 'types/index';

export const MotorSphere: MotorSphereI[] = [
  {
    name: 'Лежа на животе, поднимает голову (1 мес.)'
  },
  {
    name: 'Рефлекс переступания (1 мес.)'
  },
  {
    name: 'Рефлекторное обхватывание игрушки, пальца взрослого (1 мес.)'
  },
  {
    name: 'Лежа на животе, приподнимается на руках (2 мес.)'
  },
  {
    name: 'Лежа на животе, поднимается на локтях (3 мес.)'
  },
  {
    name: 'Лежа на животе, приподнимается, опирается на ладоши (4 мес.)'
  },
  {
    name: 'Переворачивается с живота на спину (4 мес.)'
  },
  {
    name: 'Переворачивается со спины на живот (5 мес.)'
  },
  {
    name: 'Пытается ползать (5 мес.)'
  },
  {
    name: 'Хватает погремушку (5 мес.)'
  },
  {
    name: 'Сидит без поддержки (6 мес.)'
  },
  {
    name: 'Берет предметы одной рукой (6 мес.)'
  },
  {
    name: 'Садиться (7,5 мес.)'
  },
  {
    name: 'Пытается брать мелкие предметы (7 мес.)'
  },
  {
    name: 'Ползает (8 мес.)'
  },
  {
    name: 'Встает, держась за опору (8 мес.)'
  },
  {
    name: 'Берет мелкие предметы (8 мес.)'
  },
  {
    name: 'Изучает погремушку (8 мес.)'
  },
  {
    name: 'Стучит кубиками (9 мес.)'
  },
  {
    name: 'Звенит погремушкой (9 мес.)'
  },
  {
    name: 'Берет мелкие предметы большим и указательным пальцами (11 мес.)'
  },
  {
    name: 'Самостоятельно ходит, но часто падает (12 мес.)'
  },
  {
    name: 'Кладет мелкие предметы в ёмкости (12 мес.)'
  },
  {
    name: 'Умышлено бросает предметы (12 мес.)'
  },
  {
    name: 'Черкает карандашом (12 мес.)'
  },
  {
    name: 'Бегает, но часто падает (15 мес.)'
  },
  {
    name: 'Высыпает мелкие предметы из бутылки (15 мес.)'
  },
  {
    name: 'Держит карандаш и оставляет им следы на бумаге (15 мес.)'
  },
  {
    name: 'Черкает карандашом целенаправленно и старательно (18 мес.)'
  },
  {
    name: 'После наблюдения повторяет увиденное действие с игрушкой (18 мес.)'
  },
  {
    name: 'Ходит уверенно, редко падает (18 мес.)'
  },
  {
    name: 'Строит башню из трех кубиков (18 мес.)'
  },
  {
    name: 'Использует предметы в качестве инструмента (18 мес.)'
  },
  {
    name: 'Бросает мяч в ящик (21 мес.)'
  },
  {
    name: 'Бегает (21 мес.)'
  },
  {
    name: 'Черкает вертикальные и горизонтальные линии (24 мес.)'
  },
  {
    name: 'Пробует ловить брошенный мяч (24 мес.)'
  },
  {
    name: 'Поднимается и спускается по лестнице с поддержкой (24 мес.)'
  },
  {
    name: 'Умеет чертить кривую, замкнутую линию (30 мес.)'
  },
  {
    name: 'Бегает значительно лучше (30 мес.)'
  },
  {
    name: 'Рисует шар, яблоко и другие предметы (36 мес.)'
  },
  {
    name: 'Ставит 10 кубиков один на другой (36 мес.)'
  },
  {
    name: 'Поднимается и спускается по ступенькам без опоры (36 мес.)'
  }
];

export const SpeechAndCommunication: MotorSphereI[] = [
  {
    name: 'Улыбается (6 нед.)Улыбается (6 нед.)'
  },
  {
    name: 'Гулит (воркует) (2 мес.)'
  },
  {
    name: 'Следит взглядом за предметами, находящимися перед глазами (2 мес.)'
  },
  {
    name: 'Оборачивается на голос (4 мес.)'
  },
  {
    name: 'Громко смеется (4,5 мес.)'
  },
  {
    name: 'Оборачивается на звуки (5 мес.)'
  },
  {
    name: 'Имитирует речь (лепечет) (6 мес.)'
  },
  {
    name: 'Понимает слово «НЕТ» (8 мес.)'
  },
  {
    name: 'Произносит «папа», «мама» неосознанно (8 мес.)'
  },
  {
    name: 'Жестикулирует (9 мес.)'
  },
  {
    name: 'Называет родителей «папа», «мама» (10 мес.)'
  },
  {
    name: 'Произносит первое слово (не «папа/мама») (11 мес.)'
  },
  {
    name: 'Произносит слова осмысленно, но речь непонятная (12 мес.)'
  },
  {
    name: 'Правильно называет 4-6 предметов, когда ему указывают на них (15 мес.)'
  },
  {
    name: 'Произносит 20 и более слов (15 мес.)'
  },
  {
    name: 'Стремится связать 2 слова в предложение (15 мес.)'
  },
  {
    name: 'Употребляет 30-40 слов (18 мес.)'
  },
  {
    name: 'Называет предметы на картинке (18 мес.)'
  },
  {
    name: 'Узнает членов семьи (18 мес.)'
  },
  {
    name: 'Знает название трех частей тела (18 мес.)'
  },
  {
    name: 'Речь понятная (произносит простые слова) (18 мес.)'
  },
  {
    name: 'Понимает слова, следует  простым указаниям, например, «возьми бутылочку», «покажи мне свой нос» и т.п. (18 мес.)'
  },
  {
    name: 'Называет 5 изображений на рисунке (21 мес.)'
  },
  {
    name: 'Задает первые вопросы (24 мес.)'
  },
  {
    name: 'Общается с детьми (24 мес.)'
  },
  {
    name: 'Задает различные вопросы (30 мес.)'
  },
  {
    name: 'Может связно рассказывать (30 мес.)'
  },
  {
    name: 'В игре входит в роль (36 мес.)'
  }
];

export const SelfCareSkills: MotorSphereI[] = [
  {
    name: 'Держит ложку во время еды (15 мес.)'
  },
  {
    name: 'Пьет из чашки (15 мес.)'
  },
  {
    name: 'Пробует самостоятельно есть (18 мес.)'
  },
  {
    name: 'Помогает одевать себя (18 мес.)'
  },
  {
    name: 'Самостоятельно ест (21 мес.)'
  },
  {
    name: 'Регулирует отправление физиологических потребностей (24 мес.)'
  },
  {
    name: 'Расстегивает 3 пуговицы (30 мес.)'
  },
  {
    name: 'Регулярно сообщает о физиологической потребности (30 мес.)'
  },
  {
    name: 'Сам раздевается и одевается (36 мес.)'
  }
];

export const Ear: MotorSphereI[] = [
  {
    name: 'При резком звуке вздрагивает и моргает (0 мес.)'
  },
  {
    name: 'Реагирует на звук и голос взрослого поворотом головы (2 мес.)'
  },
  {
    name: 'Прислушивается к звукам и голосам (2 мес.)'
  },
  {
    name: 'Узнает голос матери и различает его интонацию (3 мес.)'
  }
];

export const Vision: MotorSphereI[] = [
  {
    name: 'Моргает в ответ на зрительный раздражитель (1 мес.)'
  },
  {
    name: 'Фиксирует взгляд на любом световом источнике (пламя свечи, свет фонарика в темноте) (1 мес.)'
  },
  {
    name: 'Удерживает в поле зрения движущийся предмет (1 мес.)'
  },
  {
    name: 'Следит взглядом за предметами (в том числе и лице матери), находящимися перед глазами (2 мес.)'
  },
  {
    name: 'Согласованное движения обоих глаз (3 мес.)'
  },
  {
    name: 'Следит взглядом в диапазоне 180 градусов (3 мес.)'
  }
];
