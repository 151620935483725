// Libraries
import React from 'react';

// Components

// Hooks
import {CaseContext} from "./hooks";

const STEPS = [];

const Wizard = () => {
  const { step, finished, fail } = React.useContext(CaseContext);

  return (
    <div style={{width: '100%'}}>
     13
    </div>
  );
};

// Exports
export default Wizard;
