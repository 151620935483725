// Libraries
import React from "react";
import {Route, Switch} from "react-router-dom";
import {get} from "lodash";

// Components
import Thing from './Thing/Thing';

// Services
import {compose} from "recompose";
import {connect} from "react-redux";

type THINGS_PROPS_TYPE = {
  role?: string,
}

const Things = ({ role }: THINGS_PROPS_TYPE): JSX.Element => {
  return (
    <Switch>
      {
        role && (
          <>
            <Route exact path={'/things/:id'} component={Thing} />
          </>
        )
      }
    </Switch>
  )
};

const mapStateToProps = (state: object): object => ({
  role: get(state, 'session.roles[0].name')
});

export default compose<THINGS_PROPS_TYPE, any>(connect(mapStateToProps, null))(Things);
