// Libraries
import React, { useState } from "react";
import {FieldArray} from "redux-form";
import classNames from "classnames";
import { v4 as uuidv4 } from 'uuid';
import {Link} from "react-router-dom";

// Components
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {Checkbox, Input, Radio, Select} from "components/Form";
import {Button} from "components/Button";
import Modal from "components/Modal/Modal";

// Styles
import styles from './Main.module.scss';
import {useThings} from "../../../../../hooks";
function createRow(targetName: string, isAgreement: boolean, createdAt: string) {
  return { id: uuidv4(), targetName, isAgreement, createdAt };
}

const rows = [
  createRow('Цель плана', true, '20.02.2020'),
  createRow('Цель плана', true, '20.02.2020'),
  createRow('Цель плана', true, '20.02.2020')
];

const Main = ({ formValues }: any): JSX.Element => {
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState<boolean>(false);
  const [isOpenReasonModal, setIsOpenReasonModal] = useState<boolean>(false);
  const usedThings = useThings({});

  const handleSave = () => {
    console.log('usedThings', usedThings)
  };

  const renderEmployers = ({ fields }: any): JSX.Element => {
    return (
      <>
        {
          fields.map((employer: any, i: number) => (
            <Select
              key={uuidv4()}
              className={styles.Input}
              name={`${employer}.name`}
              label={`Специалист ${i + 1}`}
              placeholder={`Специалист ${i + 1}`}
            />
          ))
        }
        <Button
          classes={{ root: styles.PaperAddEmployer }}
          onClick={(): void => fields.push()}
        >+
        </Button>
      </>
    )
  };

  const renderClose = () => (
    <Select
      className={classNames(styles.Input, styles.Input_w100)}
      name="thing.reason"
      label="Причина"
      placeholder="Причина"
      options={[
        {
          id: uuidv4(),
          name: 'Отказать',
          value: 'reason_rejected_by_our'
        },
        {
          id: uuidv4(),
          name: 'Завершено',
          value: 'reason_success'
        },
        {
          id: uuidv4(),
          name: 'Отказ клиента',
          value: 'reason_rejected_by_client'
        },
        {
          id: uuidv4(),
          name: 'Разовая помощь',
          value: 'reason_help'
        },
      ]}
    />
  );

  return (
    <div className={styles.Root}>
      <div className={styles.Header}>
        <div className={styles.HeaderStatus}>
          Открыто
        </div>
        <div className={styles.HeaderInfo}>
          <p>Дата обращения: 19.09.2025</p>
          <p>Зарегистрировал обращение: Жукова М.А</p>
        </div>
      </div>

      <Paper className={styles.Paper}>
        <Select
          className={styles.Input}
          name="thing.program"
          label="Программа"
          placeholder="Программа"
        />
        <div style={{margin: '15px 0', gridColumn: '1 / 4'}}>
          <p className={styles.PaperSubTitle}>Обратившийся</p>
          <Radio
            name="thing.applier"
            options={[
              {
                id: uuidv4(),
                name: 'Обратился сотрудник',
                value: 'is_employee'
              },
              {
                id: uuidv4(),
                name: 'Не член семьи',
                value: 'is_not_family_member'
              },
              {
                id: uuidv4(),
                name: 'Член семьи',
                value: 'is_family_member'
              }
            ]}
          />
        </div>
        {formValues?.thing?.applier === 'is_employee' && (
          <>
            <Select
              className={styles.Input}
              name="thing.employee"
              options={[]}
              label="Выберите сотрудника"
              placeholder="Выберите сотрудника"
            />
            <Select
              className={styles.Input}
              name="thing.is_not_family_member.name"
              options={[]}
              label="Должность"
              placeholder="Должность"
            />
          </>
        )}
        {formValues?.thing?.applier === 'is_not_family_member' && (
          <>
            <Input
              className={styles.Input}
              name="thing.name"
              label="Имя"
              placeholder="Имя"
            />
            <Input
              className={styles.Input}
              name="thing.surname"
              label="Фамилия"
              placeholder="Фамилия"
            />
            <Input
              className={styles.Input}
              name="thing.patronymic"
              label="Отчество"
              placeholder="Отчество"
            />
            <Input
              className={styles.Input}
              name="thing.phone"
              label="Телефон"
              placeholder="Телефон"
            />
            <Input
              className={styles.Input}
              name="thing.status_person"
              label="Статус обратившегося"
              placeholder="Статус обратившегося"
            />
            <Input
              className={classNames(styles.Input, styles.PaperAddress)}
              name="thing.address_person"
              label="Фактический адрес"
              placeholder="Фактический адрес"
            />
          </>
        )}
        {formValues?.thing?.applier === 'is_family_member' && (
          <Select
            className={styles.Input}
            name="thing.family_member"
            options={[]}
            label="Член семьи"
            placeholder="Член семьи"
          />
        )}
      </Paper>
      <Paper className={styles.Paper}>
        <p className={styles.PaperSubTitle}>Специалисты</p>
        <Input
          className={styles.Input}
          name="thing.employer"
          label="Специалист создавший"
          placeholder="Специалист создавший"
        />
        <Input
          className={classNames(styles.Input, styles.PaperEmployerField)}
          name="thing.employer_main"
          label="Ведуший специалист"
          placeholder="Ведуший специалист"
        />
        <Input
          className={classNames(styles.Input, styles.PaperEmployerField)}
          name="thing.employer_additional_1"
          label="Дополнительный специалист 1"
          placeholder="Дополнительный специалист 1"
        />
        <Input
          className={classNames(styles.Input, styles.PaperEmployerField)}
          name="thing.employer_additional_2"
          label="Дополнительный специалист 2"
          placeholder="Дополнительный специалист 2"
        />
        <FieldArray name='employers' component={renderEmployers} />
      </Paper>
      <Paper className={styles.PaperTable}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Цель</TableCell>
              <TableCell align="center">Согласование</TableCell>
              <TableCell align="center">Дата создания</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row): JSX.Element => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  {row.targetName}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    className={styles.Input}
                    name={`is_isAgreement_${row.id}`}
                    label="План согласован"
                  />
                </TableCell>
                <TableCell align="center">{row.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <div className={styles.Actions}>
        <Button
          classes={{ root: styles.PaperAssessBtn }}
          onClick={handleSave}
        >Сохранить
        </Button>
        <Button
          classes={{ root: styles.PaperAssessBtn }}
          onClick={() => setIsOpenRemoveModal(true)}
        >Удалить
        </Button>
        <Button
          classes={{ root: styles.PaperAssessBtn }}
          onClick={() => setIsOpenReasonModal(true)}
        >Закрыть
        </Button>
      </div>
      <Modal
        classes={{ title: styles.ModalTitle, close: styles.ModalClose }}
        title="Вы действительно хотите удалить обращение?"
        isOpened={isOpenRemoveModal}
        onClose={() => setIsOpenRemoveModal(false)}
        actions={
          <div className={styles.ModalActions}>
            <Button
              classes={{ root: styles.PaperAssessBtn }}
              onClick={() => setIsOpenRemoveModal(false)}
              component={Link}
              href={`/families/new`}
            >Да
            </Button>
            <Button
              classes={{ root: styles.PaperAssessBtn }}
              onClick={() => setIsOpenRemoveModal(false)}
            >Отмена
            </Button>
          </div>
        }
      />
      <Modal
        isOpened={isOpenReasonModal}
        onClose={() => setIsOpenReasonModal(false)}
        classes={{ close: styles.ModalClose }}
        actions={
          <div className={styles.ModalActions}>
            <Button
              classes={{ root: styles.PaperAssessBtn }}
              onClick={() => setIsOpenReasonModal(false)}
              component={Link}
              href={`/families/new`}
            >Подтвердить
            </Button>
            <Button
              classes={{ root: styles.PaperAssessBtn }}
              onClick={() => setIsOpenReasonModal(false)}
            >Отмена
            </Button>
          </div>
        }
      >
        <div>
          <p>Закрытие заявки</p>
          <p>Выберите причину:</p>
          <FieldArray name='close' component={renderClose} />
          <Input
            className={classNames(styles.Input, styles.TextArea)}
            name="thing.comment"
            label="Комментарий"
            placeholder="Комментарий"
            multiline
            rowsMax={10}
          />
        </div>
      </Modal>
    </div>
  )
};

// Exports
const MAIN = {
  id: uuidv4(),
  name: 'Случай',
  data: Main
};

export default MAIN;