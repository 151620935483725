// Libraries
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import classNames from "classnames";
import {Link, useParams} from "react-router-dom";

// Components
import {DatePicker, Select} from "components/Form";
import {Button} from "components/Button";
import Thing from "./components/Thing/Thing";

// Types
import {TabElement} from "types/index";

// Data
import { Things } from './data';

// Styles
import styles from "../styles.module.scss";
import {toLower} from "lodash";

const DATA = (): JSX.Element => {
  const { id }: { id: string } = useParams();

  return (
    <>
      <div className={styles.Things}>
        <div className={styles.ThingsForm}>
          <DatePicker
            className={styles.Input}
            key="things_search.date"
            label="Дата"
            name="things_search.date"
            placeholder="Дата"
          />
          <Select
            className={classNames(styles.Input, styles.ThingsStatuses)}
            key="status"
            label="Статус"
            name="status"
            placeholder="Статус"
            required
            src="things_statuses"
            options={[
              {
                id: 0,
                name: 'Открыто',
                value: 'opened'
              },
              {
                id: 1,
                name: 'Закрыто',
                value: 'closed'
              },
              {
                id: 2,
                name: 'Отказ',
                value: 'decline'
              },
            ]}
          />
        </div>
        <Button classes={{ root: styles.ThingsSearchBtn}}>Найти</Button>
      </div>
      <div className={styles.ThingsContent}>
        {
          Things?.map((thing: any) => (
            <Thing key={thing.id} thing={thing} />
          ))
        }
      </div>
      <Button
        component={Link}
        classes={{ root: styles.ThingsAdd }}
        href={`/things/new:${id}`}
      >
        Добавить случай
      </Button>
    </>
  )
};

const THINGS: TabElement = {
  id: uuidv4(),
  name: 'Случаи',
  data: DATA
}

export default THINGS;
