import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';

// Views
import Main from 'views/Main';
import Contacts from 'views/Contacts';
import Feedback from 'views/Feedback';
import Auth from 'views/Auth';
import Users from 'views/Users';
import Questionnaires from 'views/Questionnaires';
import Errors from 'views/Errors';
import Families from "views/Families/Families";
import Things from "views/Things/Things";
import OtherPeople from "views/OtherPeople/OtherPeople";
import Cases from "views/Cases/Cases";

// Styles
import './App.scss';

// Config
import { THEME } from 'config';

type APP_PROPS_TYPE = {
  match: {
    url: string
  }
}

const App = ({ match }: APP_PROPS_TYPE): React.ReactElement<typeof ThemeProvider> => (
  <ThemeProvider theme={THEME}>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/contacts" component={Contacts} />
      <Route exact path="/feedback" component={Feedback} />
      <Route path="/auth" component={Auth} />
      <Route path={['/users', '/administrators', '/specialists', '/clients']} component={Users} />
      <Route path={'/families'} component={Families} />
      <Route path="/questionnaires" component={Questionnaires} />
      <Route path="/things" component={Things} />
      <Route path="/other-people" component={OtherPeople} />
      <Route path="/cases" component={Cases} />
      <Route component={Errors} path={match.url} />
    </Switch>
  </ThemeProvider>
);

export default App;
