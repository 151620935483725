// Libraries
import React from "react";
import {compose} from "recompose";
import {connect} from "react-redux";
import {get} from "lodash";

// Components
import { Page } from "components/Page";
import Title from './components/Title';
import Wizard from "./components/Wizard";

// Hooks
import { useThings, ThingsContext } from "./hooks";

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Styles
import styles from './Thing.module.scss';

// Data
import {PATHS_MAP} from "./data";
import {useParams} from "react-router-dom";

type THINGS_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string,
    path: string,
  }
};

const Thing = ({ match, session }: THINGS_PROPS_TYPE): JSX.Element => {
  const { id }: { id: string } = useParams();

  const [thing_id, user_id] = id.split(':');
  const isNew = thing_id === 'new';

  const { path } = match;
  const { role, title } = get(PATHS_MAP, `['${path.split('/:id')[0]}']`, PATHS_MAP['/things']);
  const usedThings = useThings({ session, role, title });
  const { loading } = usedThings;

  return (
    <Page loading={loading}>
      <div className={styles.Wrapper}>
        <ThingsContext.Provider value={usedThings}>
          <Title className={styles.Title} />
          <div className={styles.Actions}>
            <Wizard />
          </div>
        </ThingsContext.Provider>
      </div>
    </Page>
  )
};

const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

export default compose<THINGS_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Thing);

