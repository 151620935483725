// Libraries
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router } from 'react-router-dom';


import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/es/integration/react';

// Styles
import 'styles/reset.scss';

// App
import App from './App';

// Store
import createStore from 'store';

// Store
import { HISTORY, CLIENT } from 'config';

// Helpers
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from 'helpers/serviceWorker';

// Create store
const { persistor, store } = createStore();

// Render
const $root: HTMLElement | null | undefined = document.getElementById('root');

$root &&
  ReactDOM.render(
    <PersistGate persistor={persistor}>
      <ApolloProvider client={CLIENT}>
        <Provider store={store}>
          <Router history={HISTORY}>
            <Route component={App} path="/" />
          </Router>
        </Provider>
      </ApolloProvider>
    </PersistGate>,
    $root
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
