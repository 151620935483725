// Libraries
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import BaseForm from 'components/Form';
import Tabs from 'components/Tabs/Tabs';

// Styles
import styles from './styles.module.scss';

// Services
import { SESSION_TYPE, SIGN_IN_TYPE } from 'services/session';
import { OPTION_TYPE } from 'services/options';

// Hooks
import { useStep } from './hooks';

// Types
import {TabElement} from "types";

// Data
import { validate, asyncValidate } from './validators';
import DATA from './data/index';

type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  formValues?: object,
  session?: SESSION_TYPE,
  genders?: [OPTION_TYPE],
  clientsStatuses?: [OPTION_TYPE],
  roles?: [OPTION_TYPE],
  signIn: SIGN_IN_TYPE,
  handleSubmit: Function,
  initialize: (data: object) => void,
  reset: () => void,
};

const Form = ({
  valid,
  anyTouched,
  asyncValidating,
  formValues,
  session,
  genders,
  clientsStatuses,
  roles,
  signIn,
  handleSubmit: formSubmit,
  initialize,
  reset,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { error, handleSubmit } = useStep({
    session,
    formValues,
    signIn,
    reset,
    initialize,
    roles
  });
  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="things"
      autoComplete="off"
    >
      <Tabs data={DATA.map((item: TabElement) => (
        {
          ...item,
          data: item.data({ error, formValues })
        }
      ))} />
    </BaseForm>
  );
};

// Exports
const familiesForm = reduxForm<object, any>({
  form: 'things',
  destroyOnUnmount: false,
  asyncValidate,
  validate,
  touchOnChange: true,
  enableReinitialize: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  session: get(state, 'session'),
  genders: get(state, 'options.genders'),
  roles: get(state, 'options.roles'),
  clientsStatuses: get(state, 'options.clients_statuses'),
  formValues: get(state, 'form.things.values'),
  initialValues: {
    employers: [''],
    thing_actions: [''],
    thing: {
      applier: "is_family_member"
    }
  },
});

// Exports
export default compose(connect(mapStateToProps))(familiesForm);
