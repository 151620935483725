// @flow
import { PURGE } from 'redux-persist';

import type { ACTION_TYPE } from './actions';

// Types
import { SET_TEMPORARY } from './types';

export const INITIAL_STATE = {
};

export const reducer = (state: {} = INITIAL_STATE, action: ACTION_TYPE) => {
  switch (action.type) {
    case SET_TEMPORARY:
      return {
        ...state,
        ...action.payload,
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
