// Components
import contracts from '../components/contracts/Contracts';
import main from '../components/Main';
import plan from '../components/plan/Plan';
import diary from '../components/diary/Diary';
import questionnaires from '../components/questionnaires/Questionnaires';

// Types
import {ReactNode} from "react";

export interface DATA_ITEM_TYPE {
  id: string,
  name: string,
  data: ReactNode,
}

export type DATA_TYPE = DATA_ITEM_TYPE[];

const DATA: DATA_TYPE = [
  main,
  questionnaires,
  contracts,
  plan,
  diary
];

export default DATA;