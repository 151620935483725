// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';

// Components
import List from './List';
import EFOs from './EFOs';
import ODREVs from "./ODREVs";
import PSSs from "./PSSs";
import SNAs from "./SNAs";
import QBs from "./QBs";

// Services
import { ACTION_TYPE } from 'services/session';

// Views
import Errors from 'views/Errors';

type QUESTIONNAIRES_PROPS_TYPE = {
  role?: string,
  match: {
    url: string
  }
}

const Questionnaires = ({ role, match }: QUESTIONNAIRES_PROPS_TYPE): React.ReactElement<typeof Switch> => (
  <Switch>
    {
      role && (
        <>
          <Route exact component={List} path="/questionnaires" />
          <Route component={EFOs} path="/questionnaires/efo" />
          <Route component={ODREVs} path="/questionnaires/odrev" />
          <Route component={PSSs} path="/questionnaires/pss" />
          <Route component={SNAs} path="/questionnaires/sna" />
          <Route component={QBs} path="/questionnaires/qb" />
        </>
      )
    }
    <Route component={Errors} path={match.url} />
  </Switch>
);

const mapStateToProps = (state: object): object => ({
  role: get(state, 'session.roles[0].name')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<QUESTIONNAIRES_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Questionnaires);
