import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { get, set } from 'lodash';

// Reducers
import services from 'services';

// Config
import { APP_NAME, APP_VERSION } from 'config';

const __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__');

const composeEnhancers: Function =
  typeof window === 'object' && __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const migrations = set({}, `[${APP_VERSION}]`, services);

const persistedReducer = persistReducer<any>(
  {
    key: APP_NAME,
    storage,
    version: APP_VERSION,
    blacklist: ['loader', 'temporary'],
    stateReconciler: hardSet,
    migrate: createMigrate(migrations)
  },
  services
);

const handleStore = (): { persistor: any, store: any} => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);

  return { persistor, store };
};

export default handleStore;
