// Libraries
import React, { Fragment } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from 'uuid';

// Components
import {FieldArray} from "redux-form";
import {Paper} from "@material-ui/core";
import {Checkbox, Input, Select} from "components/Form";
import {Button} from "components/Button/Button";

// Styles
import styles from './styles.module.scss';

const Plan = (): JSX.Element => {
  const [createMode, setCreateMode] = React.useState(false);

  const renderActions = ({ fields, meta: { error } }: any): JSX.Element => (
    <>
      {createMode ? (
        <>
          <Input
            className={styles.Input}
            label="Дата создания"
            name={`created_at`}
            placeholder="Дата создания"
          />
          <Input
            className={styles.Input}
            label="Дата изменения"
            name={`changed_at`}
            placeholder="Дата изменения"
          />
          <Select
            className={classNames(styles.Input, styles.Select)}
            name={`questionnaire`}
            options={[]}
            label="Анкета"
            placeholder="Анкета"
          />
          <Input
            className={classNames(styles.Input, styles.PaperTarget)}
            label="Цель плана"
            name={`target`}
            placeholder="Цель плана"
            fullWidth
          />
          {
            fields.map((action: any, i: number): JSX.Element => (
              <Fragment key={uuidv4()}>
                <div className={styles.ActionTitle}>
                  <p>Действие {i + 1}</p>
                  <Button
                    classes={{ root: styles.ActionRemove }}
                    onClick={() => fields.remove(i)}
                  >-
                  </Button>
                </div>
                <Select
                  className={classNames(styles.Input, styles.Select)}
                  name={`${action}.section_evaluation`}
                  label="Раздел оценки"
                  placeholder="Раздел оценки"
                />
                <Input
                  className={styles.Input}
                  label="Описание"
                  name={`${action}.description`}
                  multiline
                  rowsMax={6}
                  placeholder="Описание"
                />
                <Input
                  className={styles.Input}
                  label="Сроки"
                  name={`${action}.deadlines`}
                  placeholder="Сроки"
                />
                <Input
                  className={classNames(styles.Input, styles.Comment)}
                  key={`${action}.comment`}
                  label="Ответственный"
                  multiline
                  rowsMax={10}
                  name={`${action}.comment`}
                  placeholder="Ответственный"
                />
                <Checkbox
                  className={classNames(styles.Input, styles.Checkbox)}
                  name={`${action}.is_done`}
                  label="Выполнен?"
                />
                <Checkbox
                  className={classNames(styles.Input, styles.Checkbox)}
                  name="is_agreement_plan"
                  label="План согласован?"
                />
              </Fragment>
            ))
          }
          <Button
            classes={{ root: styles.ActionAdd }}
            onClick={() => fields.push()}
          >
            Добавить действие
          </Button>
          <div className={styles.Buttons}>
            <Button
              classes={{ root: classNames(styles.Button, styles.ActionAdd) }}
              onClick={() => {
                alert('отсылаем на бэк');
                setCreateMode(false);
              }}
            >
              Сохранить
            </Button>
            <Button
              classes={{ root: classNames(styles.Button, styles.ActionAdd) }}
              onClick={() => setCreateMode(false)}
            >
              Отмена
            </Button>
            <Button
              classes={{ root: classNames(styles.Button, styles.ActionAdd) }}
            >
              Печать
            </Button>
          </div>
        </>
      ) : (
        <Button
          classes={{ root: classNames(styles.Button, styles.ActionAdd) }}
          onClick={() => setCreateMode(true)}
        >
          Создать план
        </Button>
      )}
    </>
  );

  return (
    <div>
      <p>Планы</p>
      <Paper className={styles.Paper}>
        <FieldArray name='thing_actions' component={renderActions} />
      </Paper>
    </div>
  )
};

const PLAN = {
  id: uuidv4(),
  name: 'Планы',
  data: Plan
};

export default PLAN;
