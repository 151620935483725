// Libraries
import React from "react";
import {Link} from "react-router-dom";

// Components
import Page from "components/Page";
import {H1} from "components/Typography";
import Button from "components/Button/Button";
import Table from "components/Table";

// Styles
import styles from "./List.module.scss";

// Types
import {SESSION_TYPE} from "services/session";

type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string
  }
};

const List = ({ match: { url } }: LIST_PROPS_TYPE): JSX.Element => {
  return (
    <Page>
      <H1>
        Социальные помощники
      </H1>
      <Table
        columns={[]}
        data={[]}
        additional={{
          toolbar: (
            <Button component={Link} className={styles.Button} href={`${url}/new`}>
              Создать
            </Button>
          )
        }}
      />
    </Page>
  )
};

export default List;
