// Libraries
import React, {useEffect} from 'react';
import { reduxForm } from 'redux-form';
import {connect, useDispatch, useSelector} from 'react-redux';
import { compose } from 'recompose';
import {get, toLower} from 'lodash';

// Components
import Button from 'components/Button';
import BaseForm from 'components/Form';
import { Tabs } from 'components/Tabs';

// Styles
import styles from './styles.module.scss';

// Services
import { signIn, SESSION_TYPE, SIGN_IN_TYPE, ACTION_TYPE } from 'services/session';
import { OPTION_TYPE } from 'services/options';

// Hooks
import { useStep } from './hooks';

// Data
import { validate, asyncValidate } from './validators';
import DATA from './data';

// Types
import {TabElement} from "types/index";
import {useHistory, useParams} from "react-router-dom";
import {useLazyQuery} from "react-apollo";
import {SERVER_ERRORS_HANDLER} from "../../../../../../../config";


type FORM_PROPS_TYPE = {
  valid?: boolean,
  asyncValidating?: boolean | string,
  anyTouched?: boolean,
  formValues?: object,
  session?: SESSION_TYPE,
  genders?: [OPTION_TYPE],
  clientsStatuses?: [OPTION_TYPE],
  roles?: [OPTION_TYPE],
  signIn: SIGN_IN_TYPE,
  handleSubmit: Function,
  initialize: (data: object) => void,
  reset: () => void,
};

const Form = ({
  valid,
  anyTouched,
  asyncValidating,
  formValues,
  session,
  genders,
  clientsStatuses,
  roles,
  signIn,
  handleSubmit: formSubmit,
  initialize,
  reset,
}: FORM_PROPS_TYPE): React.ReactElement<typeof BaseForm> => {
  const { id }: { id: string } = useParams();
  const isNew = toLower(id) === 'new';

  const { error, handleSubmit } = useStep({
    session,
    formValues,
    signIn,
    reset,
    initialize,
    roles
  });

  const BLOCKED_TABS_IF_NEW_FAMILY = ['Случаи', 'Доступы в ЛК'];

  return (
    <BaseForm
      className={styles.Form}
      onSubmit={formSubmit(handleSubmit)}
      name="families"
      autoComplete="off"
    >
      <Tabs
        data={DATA.map((item: TabElement) => (
          {
            ...item,
            data: item.data({ error }),
            disabled: isNew && BLOCKED_TABS_IF_NEW_FAMILY.includes(item.name)
          }
        ))}
      />
      <Button
        className={styles.Button}
        icon="arrow"
        type="submit"
      >
        Сохранить
      </Button>
    </BaseForm>
  );
};

// Exports
const familiesForm = reduxForm<object, any>({
  form: 'families',
  // destroyOnUnmount: false,
  touchOnChange: true,
  enableReinitialize: true,
})(Form);

const mapStateToProps = (state: object): object => ({
  session: get(state, 'session'),
  genders: get(state, 'options.genders'),
  roles: get(state, 'options.roles'),
  clientsStatuses: get(state, 'options.clients_statuses'),
  formValues: get(state, 'form.families.values'),
  initialValues: {
    members: get(state, 'form.families.default_values.members') || [''],
    kids: get(state, 'form.families.default_values.kids') || ['']
  },
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
  signIn: (user: SESSION_TYPE) => dispatch(signIn(user)),
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(familiesForm);
