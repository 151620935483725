// Libraries
import React, { Fragment } from "react";
import classNames from "classnames";
import { v4 as uuidv4 } from 'uuid';

// Components
import {FieldArray} from "redux-form";
import {Paper} from "@material-ui/core";
import {Checkbox, Input, Select} from "components/Form";
import {Button} from "components/Button/Button";

// Styles
import styles from './styles.module.scss';


const Questionnaires = (): JSX.Element => {

  const renderActions = ({ fields, meta: { error } }: any): JSX.Element => (
    <>
      <Select
        className={classNames(styles.Input, styles.Select)}
        name={`questionnaire`}
        options={[]}
        label="Анкета"
        placeholder="Анкета"
      />
      <Button
        classes={{ root: classNames(styles.Button, styles.ActionAdd) }}
      >
        Создать анкету
      </Button>
      <Button
        classes={{ root: classNames(styles.Button, styles.ActionAdd) }}
      >
        Отправить анкету
      </Button>
    </>
  );

  return (
    <div>
      <p>Анкеты</p>
      <Paper className={styles.Paper}>
        <FieldArray name='thing_actions' component={renderActions} />
      </Paper>
    </div>
  )
};


const QUESTIONNAIRES = {
  id: uuidv4(),
  name: 'Анкеты',
  data: Questionnaires
};

export default QUESTIONNAIRES;