import { get, omit } from 'lodash';

import type { Action } from './actions';

import { CLOSE_MODAL, CLOSE_MODALS, OPEN_MODAL } from './types';

const initialState = {};

export const reducer = (state: Object = initialState, action: Action) => {
  const id = get(action, 'id');
  switch (action.type) {
    case CLOSE_MODAL:
      return id && omit(state, id);
    case CLOSE_MODALS:
      return initialState;
    case OPEN_MODAL:
      return {
        ...state,
        ...(id && {
          [id]: action.payload || true
        })
      };
    default:
      return state;
  }
};

export default reducer;
