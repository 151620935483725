// Libraries
import React from 'react';
import { get } from 'lodash';

// Components
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

// Styles
import styles from './List.module.scss';

export const COLUMNS = [
  {
    Header: 'Обратился',
    accessor: 'applied',
  },
  {
    Header: 'Создал',
    accessor: 'creator',
  },
  {
    Header: 'Программа',
    accessor: 'programm',
  },
  {
    Header: 'Действия',
    accessor: 'actions',
  },
];

export const GET_ACTIONS_CELL = (user: object): React.ReactElement<typeof Box> => (
  <Box>
    <IconButton
      edge="start"
      color="inherit"
      className={styles.IconButton}
      onClick={get(user, 'onEdit')} 
    >
      <EditIcon />
    </IconButton>
  </Box>
);
