// Libraries
import React from 'react';
import { get } from 'lodash';

// Components
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';

// Styles
import styles from './List.module.scss';
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

export const COLUMNS = [
  {
    Header: 'Наименование',
    accessor: 'name',
  },
  {
    Header: 'Действия',
    accessor: 'actions',
  },
];

export const GET_ACTIONS_CELL = (item: object): React.ReactElement<typeof Box> => (
  <Box>
    <IconButton
      edge="start"
      color="inherit"
      className={styles.IconButton}
      onClick={get(item, 'onEdit')}
    >
      <EditIcon />
    </IconButton>
    <IconButton
      edge="start"
      color="inherit"
      className={styles.IconButton}
      onClick={get(item, 'onDelete')}
    >
      <DeleteForeverIcon />
    </IconButton>
  </Box>
);
