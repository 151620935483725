// Libraries
import React from "react";
import {Route, Switch} from "react-router-dom";
import {get} from "lodash";
import {compose} from "recompose";
import {connect} from "react-redux";

// Components
import Errors from "../Errors/Errors";
import List from "./List/List";
import OtherPerson from "./OtherPerson/Form";

// Types
import {ACTION_TYPE} from "services/session";

type OTHER_PEOPLE_PROPS_TYPE = {
  role?: string,
  match: {
    url: string
  }
}

const OtherPeople = ({ role, match }: OTHER_PEOPLE_PROPS_TYPE): JSX.Element => {
  return (
    <Switch>
      {
        role && (
          <>
            <Route exact path="/other-people" component={List} />
            <Route exact path="/other-people/new" component={OtherPerson} />
          </>
        )
      }
      <Route component={Errors} path={match.url} />
    </Switch>
  )
};

const mapStateToProps = (state: object): object => ({
  role: get(state, 'session.roles[0].name')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

export default compose<OTHER_PEOPLE_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(OtherPeople);
