import { combineReducers } from 'redux';

// Reducers
import { reducer as form } from 'redux-form';
import session from './session';
import modals from './modals';
import options from './options';
import temporary from './temporary';

const servicesReducer = combineReducers({
  form,
  session,
  modals,
  options,
  temporary,
});

export default servicesReducer;
