import main from './main';
import access from './access';
import things from './things';
import {TabElement} from "types/index";

const DATA: TabElement[] = [
  main,
  access,
  things
];

export default DATA;
