// Libraries
import React from 'react';
import classNames from 'classnames';

// Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

import Button from 'components/Button';


// Styles
import styles from './Modal.module.scss';

export interface MODAL_PROPS_TYPES {
  children?: JSX.Element,
  classes?: {
    root?: string,
    backdrop?: string,
    container?: string,
    content?: string,
    title?: string,
    close?: string
  },
  isOpened: boolean,
  onClose?: () => void,
  title?: any,
  actions?: JSX.Element,
}

const Modal = ({
  children,
  classes = {},
  title,
  actions,
  onClose,
  isOpened,
  ...props
}: MODAL_PROPS_TYPES): React.ReactElement<typeof Dialog> => (
  <Dialog
    className={classNames(classes.root, styles.Root)}
    onClose={onClose}
    open={isOpened}
    {...props}
    classes={{container: classes.container, paper: classes.container}}
  >
    <Button
      onClick={onClose}
      variant="text"
      className={classNames(
        classes?.close,
        styles.Close
      )}
    />
    <DialogTitle
      className={classNames(classes.title, styles.Title)}
    >
      {title}
    </DialogTitle>
    <DialogContent
      className={classNames(classes.content, styles.Content)}
    >
      {children}
    </DialogContent>
    <DialogActions
      className={classNames(classes.content, styles.Content)}
    >
      {actions}
    </DialogActions>
  </Dialog>
);

export default Modal;
