// Libraries
import React from "react";
import {Route, Switch} from "react-router-dom";
import {get} from "lodash";
import {compose} from "recompose";
import {connect} from "react-redux";

// Components
import List from "./List/List";
import Case from "./Case/Case";

// Services
import { ACTION_TYPE } from 'services/session';

// Views
import Errors from 'views/Errors';

type FAMILIES_PROPS_TYPE = {
  role?: string,
  match: {
    url: string
  }
}

const Cases = ({ role, match }: FAMILIES_PROPS_TYPE): JSX.Element => {
  return (
    <Switch>
      {
        role && (
          <>
            <Route exact component={List} path={'/cases'} />
            <Route exact component={Case} path={'/cases/:id'} />
          </>
        )
      }
      <Route component={Errors} path={match.url} />
    </Switch>
  )
};

const mapStateToProps = (state: object): object => ({
  role: get(state, 'session.roles[0].name')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

export default compose<FAMILIES_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Cases);
