// Libraries
import React from "react";
import { v4 as uuidv4 } from 'uuid'

// Components
import {Paper} from "@material-ui/core";
import {TabElement} from "../../../../../../../../types";
import {Input, Radio} from "../../../../../../../../components/Form";

// Styles
import styles from '../styles.module.scss';
import {H5, Subtitle} from "../../../../../../../../components/Typography";

const DATA = (): JSX.Element => {
  return (
    <>
      <H5
        className={styles.H5}
      >
        Доступы в личный кабинет
      </H5>
      <Paper className={styles.ThingPaper}>
        <Input
          className={styles.Input}
          key="fio"
          label="ФИО"
          name="fio"
          placeholder="ФИО"
          required
        />
        <Input
          className={styles.Input}
          type="tel"
          key="phone"
          label="Телефон"
          name="phone"
          placeholder="Телефон"
          required
        />
        <Input
          className={styles.Input}
          type="password"
          key="password"
          label="Пароль"
          name="password"
          placeholder="Пароль"
          required
        />
      </Paper>
    </>
  )
};

const ACCESS: TabElement = {
  id: uuidv4(),
  name: 'Доступы в ЛК',
  data: DATA
}

export default ACCESS;
