import { useEffect, useContext, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { useParams } from "react-router-dom";
import validator from 'validator';
import { get, replace, capitalize, join, toLower, find } from 'lodash';
import { loader } from 'graphql.macro';
import moment from 'moment';

// Hooks
import { FamilyContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// Types
import { SIGN_IN_TYPE, SESSION_TYPE } from 'services/session';
import { OPTION_TYPE } from 'services/options';
import {useDispatch, useSelector} from "react-redux";

// GraphQL
const familyQuery = loader('src/graphql/queries/family.gql');
const familyMutation = loader('src/graphql/mutations/families.gql');

type USE_STEP_PROPS_TYPE = {
  session?: SESSION_TYPE,
  formValues?: object,
  signIn: SIGN_IN_TYPE,
  roles?: [OPTION_TYPE],
  reset: () => void,
  initialize: (data: object) => void,
};

export const useStep = ({
  session,
  formValues,
  signIn,
  reset,
  roles,
  initialize,
}: USE_STEP_PROPS_TYPE) => {
  const store = useSelector(store => store);
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const isNew = toLower(id) === 'new';

  const [ editableData, setEditableData ] = useState<object>();

  const {
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleValues,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    role,
  } = useContext(FamilyContext);

  const [
    handlefamilyMutation,
    familyMutationResult
  ] = useMutation(
    familyMutation,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: familyMutationLoading, data: familyMutationData, error: familyMutationError } = familyMutationResult;

  const [
    handleFamilyQuery,
    familyQueryResult
  ] = useLazyQuery(
    familyQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { loading: familyQueryLoading, data: familyQueryData, error: familyQueryError } = familyQueryResult;
  
  useEffect(() => {
    // console.log('find:', find(roles, { value: get(editableData, 'roles[0].name') }));
    editableData && initialize({
      ...editableData,
      login: get(editableData, 'phone') || get(editableData, 'email'),
      birthday: moment(get(editableData, 'birthday', '01-01-1900'), 'YYYY-MM-DD'),
      // role: find(roles, { value: get(editableData, 'roles[0].name') }) || (role !== 'user' ? get(roles, role) : get(roles, 'client')),
      role: find(roles, { value: get(editableData, 'roles[0].name', role !== 'user' ? role : 'client')}),
    });
  }, [editableData]);

  useEffect(() => {
    !isNew ? handleFamilyQuery({ variables: { id } }) : reset();
  }, [id]);

  useEffect(() => {
    handleLoading(!!familyMutationLoading || !!familyQueryLoading);
  }, [familyMutationLoading, familyQueryLoading]);

  useEffect(() => {
    if (!familyQueryData || loading) {
      return;
    }

    const curFamily = familyQueryData?.families?.data?.find(({ id: familyId }: any) => familyId === id);
    const user = get(familyQueryData, 'profile.user');
    const isError = get(familyQueryData, 'profile.__typename') === 'Error';
    const message = get(familyQueryData, 'profile.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(familyQueryError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? setEditableData({
        kids: curFamily.kids,
        user,
        members: curFamily.members
      })
      : handleError(join(currentErrors, '\n'));
  }, [loading, familyQueryData, familyQueryError]);

  console.log('EDIT', editableData)

  useEffect(() => {
    if (!familyMutationData || loading) {
      return;
    }
    const isError = get(familyMutationData, 'profile.__typename') === 'Error';
    const message = get(familyMutationData, 'profile.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(familyMutationError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));
  }, [loading, familyMutationData, familyMutationError]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    const REQUIRED_FIELDS = {
      members: ['surname'],
      kids: ['surname']
    };

    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );

    // @ts-ignore
    const members = formValues && formValues.members.map((member, i) => {
      const name = get(member, 'name');
      const surname = get(member, 'surname');
      const patronymic = get(member, 'patronymic');
      const phone = get(member, 'phone');
      const position = get(member, 'position');
      const passport_create = get(member, 'passport_create');
      const passport_number = +get(member, 'passport_number');
      const passport_series = get(member, 'passport_series');
      const passport_subdivision = get(member, 'passport_subdivision');
      const passport_subdivision_code = +get(member, 'passport_subdivision_code');
      const is_contact_face = get(member, 'is_contact_face');
      const gender = get(member, 'gender');
      const birthday = get(member, 'birthday');
      const status = get(member, 'status');

      return {
        passport_create: "2000-02-02",
        id: isNew ? i : member.id,
        ...(passport_number && { passport_number }),
        ...(passport_series && { passport_series }),
        ...(passport_subdivision && { passport_subdivision }),
        ...(passport_subdivision_code && { passport_subdivision_code }),
        ...(status && { status }),
        ...(is_contact_face && { is_contact_face }),
        ...(name && { name }),
        ...(surname && { surname }),
        ...(patronymic && { patronymic }),
        ...(phone && { phone }),
        // ...(position && { position }),
        ...(status && { status }),
        ...(phone && { phone }),
        // ...(birthday && { birthday }),
        ...(gender && { gender }),
        user: {}
      }
    });

    // @ts-ignore
    const kids = formValues && formValues.kids.map((kid, i) => {
      const address = get(kid, 'address');
      const name = get(kid, 'name');
      const surname = get(kid, 'surname');
      const patronymic = get(kid, 'patronymic');
      const phone = get(kid, 'phone');
      const position = get(kid, 'position');
      const document_create = get(kid, 'document_create');
      const document_number = +get(kid, 'document_number');
      const document_subdivision = get(kid, 'document_subdivision');
      const document_subdivision_code = +get(kid, 'document_subdivision_code');
      const document_type = get(kid, 'document_type');
      const gender = get(kid, 'gender');
      const birthday = get(kid, 'birthday');
      const status = get(kid, 'status');

      return {
        id: isNew ? i : kid.id,
        document_create: "2000-02-02",
        birthday: "2000-02-02",
        // ...(document_create && {document_create}),
        ...(document_number && {document_number}),
        ...(document_subdivision && {document_subdivision}),
        ...(document_subdivision_code && {document_subdivision_code}),
        ...(document_type && {document_type}),
        ...(address && {address}),
        ...(name && {name}),
        ...(surname && {surname}),
        ...(patronymic && {patronymic}),
        ...(phone && {phone}),
        ...(position && {position}),
        ...(status && {status}),
        ...(phone && {phone}),
        // ...(birthday && {birthday}),
        ...(gender && {gender}),
        user: {}
      };
    });


    // @ts-ignore
    const getErrors = (data, key) => data.reduce((reducer, fields) => {
      // @ts-ignore
      return REQUIRED_FIELDS[key].some(req => !fields[req]) ? 'ERROR' : null
    }, null)

    // @ts-ignore
    const REQUIRED_ERRORS = {
      kids: getErrors(kids, 'kids'),
      members: getErrors(members, 'members')
    }

    if (Object.values(REQUIRED_ERRORS).some(err => err)) {
      alert('Заполните обязательные поля (помеченные *) для сохранения формы');
    } else {
      const stepValues = {
        ...(!isNew && {id}),
        users: {
          upsert: [
            {
              user: {
                upsert: {
                  name: "TEST2",
                  surname: "TEST2",
                  patronymic: "TEST2",
                  birthday: "2021-06-10"
                }
              },
            }
          ]
        },
        members: {
          upsert: members
        },
        kids: {
          upsert: kids
        }
      };

      handleValues({
        ...stepValues,
      });

      return handlefamilyMutation({ variables: { input: stepValues } });
    }
  };

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit,
    role,
  };
};

export default useStep;
