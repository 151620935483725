import { v4 as uuidv4 } from 'uuid';

export const Things = [
  {
    id: uuidv4(),
    user: {
      id: uuidv4(),
      name: 'Денщинкова М.А'
    },
    statuses: ['Открыто'],
    program: 'Передышка',
    whoCreate: 'Жукова М.А',
    date: '13.13.2013'
  },
  {
    id: uuidv4(),
    user: {
      id: uuidv4(),
      name: 'Денщинкова М.А'
    },
    statuses: ['Отказ', 'Закрыто'],
    program: 'ЕФО',
    whoCreate: 'Жукова М.А',
    date: '13.13.2013'
  },
  {
    id: uuidv4(),
    user: {
      id: uuidv4(),
      name: 'Денщинкова М.А'
    },
    statuses: ['Разовая помощь', 'Закрыто'],
    program: 'Разовая помощь',
    whoCreate: 'Жукова М.А',
    date: '13.13.2013'
  }
];
