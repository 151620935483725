// Libraries
import React from 'react';
import {get} from 'lodash';
import {useHistory} from "react-router-dom";
import {useLazyQuery, useMutation} from "react-apollo";
import {loader} from "graphql.macro";

// Types
import { SESSION_TYPE } from 'services/session';

// Data
import { COLUMNS, GET_ACTIONS_CELL } from './data';

export type LIST_PROPS_TYPE = {
  session?: SESSION_TYPE,
  url: string
};

// GraphQL
const questionariesQuery = loader('src/graphql/queries/families.gql');
const deleteFamilyMutation = loader('src/graphql/mutations/deleteFamily.gql');


export const useList = ({
  session,
  url
}: LIST_PROPS_TYPE) => {
  const history = useHistory();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const token = get(session, 'token');

  const loadQuestinaries = () => {
    handleQuestionariesQuery();
  };

  React.useEffect((): any => {
    !!token && loadQuestinaries();
  },[token]);

  const columns = React.useMemo(
    () => COLUMNS,
    []
  );

  const [handleQuestionariesQuery, {
    loading: questionariesQueryLoading,
    data: questionariesQueryData
  }] = useLazyQuery(questionariesQuery, {
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    setLoading(!!questionariesQueryLoading);
  }, [questionariesQueryLoading]);

  const [
    handleDeleteFamilyMutation,
    deleteFamilyMutationResult
  ] = useMutation(
    deleteFamilyMutation,
    {
      fetchPolicy: 'no-cache',
    }
  );

  React.useEffect((): any => {
    if (!questionariesQueryData || questionariesQueryLoading) {
      return;
    }

    const families = get(questionariesQueryData, 'families.data')

    const preparedFamilies = families && families.map((family: any) => ({
      id: family.id,
      childrenCount: family.kids.length,
      updated_at: family.updated_at,
      fio: `${family.users[0].user.name.substr(0, 1).toUpperCase() + family.users[0].user.name.substr(1)} ${family.users[0].user.surname.toUpperCase().slice(0,1)}.${family.users[0].user.patronymic.toUpperCase().slice(0,1)}.`,
      countOfOpening: '5',
      actions: GET_ACTIONS_CELL({
        ...family,
        onEdit: () => history.push(`${url}/${get(family, 'id')}`),
        onDelete: () => {
          handleDeleteFamilyMutation({
            variables: {
              id: family.id
            }
          })
            .then(() => loadQuestinaries());
        }
      })
    }));

    setData(preparedFamilies);
  }, [questionariesQueryData, questionariesQueryLoading]);

  return {
    loading,
    data,
    setData,
    columns,
  };
};

export default useList;
