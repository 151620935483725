export const ADD_OPTIONS = 'SERVICES/OPTIONS/ADD_OPTIONS';

export type OPTION_TYPE = {
  id?: string | number,
  name?: string,
  value?: any
};

export type ACTION_TYPE = {
  type: string,
  payload?: object,
};
