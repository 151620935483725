// Library
import React from "react";
import {v4 as uuidv4} from "uuid";
import Table from "../../../../../../../../../components/Table";
import Button from "../../../../../../../../../components/Button/Button";
import styles from "./Diary.module.scss";
import Paper from "@material-ui/core/Paper";
import {DatePicker, Input} from "../../../../../../../../../components/Form";
import classNames from "classnames";
import moment from "moment";

const mock = [
  {
    date: '12.07.21',
    do: 'Связь с клиентом',
    about: 'Описание действия',
    author: 'Иванов И.В.'
  },
  {
    date: '12.07.21',
    do: 'Встреча',
    about: 'Описание действия',
    author: 'Иванов И.В.'
  },
  {
    date: '12.07.21',
    do: 'Выезд на дело',
    about: 'Произвели выезд',
    author: 'Иванов И.В.'
  },
  {
    date: '12.07.21',
    do: 'Планирование',
    about: 'Описание действия',
    author: 'Иванов И.В.'
  },
];

const COLUMNS = [
  {
    Header: 'Дата и время',
    accessor: 'date',
  },
  {
    Header: 'Действие',
    accessor: 'do',
  },
  {
    Header: 'Описание действия',
    accessor: 'about',
  },
  {
    Header: 'Автор',
    accessor: 'author',
  },
];

const Diary = (): JSX.Element => {
  const [data, setData] = React.useState(mock);
  const [createMode, setCreateMode] = React.useState(false);

  const handleAddRecord = () => {
    setCreateMode(false);
    alert('Запись будет добавляться когда бэк будет готов!!!!');
  }

  return (
    <div>
      <div>Дневник ведение случая</div>
      <Table
        columns={COLUMNS}
        data={data}
        additional={{
          toolbar: (
            <>
              <Button className={styles.Button} onClick={() => setCreateMode(true)}>
                Создать
              </Button>
            </>
          ),
          underToolbar: (
            <>
              {createMode && (
                <div style={{marginBottom: '30px'}}>
                  <Paper className={styles.Paper}>
                    <div style={{gridColumn: '1 / 4', marginBottom: '15px'}}>
                      Добавление записи
                    </div>
                    <Input
                      className={styles.Input}
                      name="diary.do"
                      label="Действие"
                      placeholder="Действие"
                    />
                    <Input
                      className={styles.Input}
                      name="diary.info"
                      label="Описание действия"
                      placeholder="Описание действия"
                    />
                    <Input
                      className={styles.Input}
                      name="diary.author"
                      label="Автор"
                      placeholder="Автор"
                    />
                    <Button
                      classes={{ root: styles.ActionsBtn }}
                      onClick={handleAddRecord}
                    >
                      Добавить
                    </Button>
                  </Paper>
                </div>
              )}
            </>
          )
        }}
      />
    </div>
  )
};

const DIARY = {
  id: uuidv4(),
  name: 'Дневник ведение случая',
  data: Diary,
};

export default DIARY;
