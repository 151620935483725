// Libraries
import React from "react";
import { v4 as uuidv4 } from 'uuid';
// Components
import {Paper} from "@material-ui/core";

// Styles
import styles from '../../../styles.module.scss';

interface ThingProps {
  readonly thing: any;
}

const Thing = ({ thing }: ThingProps): JSX.Element => {
  return (
    <Paper className={styles.ThingPaper}>
      <p>
        Программа: { thing.program }
      </p>
      <div>
        <span>ФИО Обратившегося: { thing.user.name }</span>
        <span>Дата обращения: { thing.date }</span>
      </div>
      <div>
        <span>
          Зарегистрировал обращение: { thing.whoCreate }
        </span>
        <span className={styles.ThingPaperStatuses}>{ thing.statuses.map((status: any) => <span key={uuidv4()} className={styles.ThingPaperStatus} data-status={status}>{ status }</span>) }</span>
      </div>
    </Paper>
  )
};

export default Thing;
