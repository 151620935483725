// Libraries
import React, {useMemo} from "react";

// Components
import {Page} from "components/Page";
import {H1, H2, Subtitle} from "components/Typography/Typography";
import {Paper, Table, TableHead, TableCell, TableBody, TableRow} from "@material-ui/core";
import {Checkbox, DatePicker, Input} from "components/Form";
import Select from "components/Form/components/Select";

// Types
import {MotorSphere as MotorSphereI} from "types/index";

// Data
import { MotorSphere, SpeechAndCommunication, SelfCareSkills, Ear, Vision } from "./data";

// Styles
import styles from "./styles.module.scss";

const QB = (): JSX.Element => {
  const renderMotorSphere = useMemo((): JSX.Element[] => {
    return MotorSphere.map((item: MotorSphereI, i: number): JSX.Element => (
      <TableRow key={`${item.name}_${i}`}>
        <TableCell>{ item.name }</TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.motor_sphere_${i}_1`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.motor_sphere_${i}_2`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.motor_sphere_${i}_3`}
          />
        </TableCell>
      </TableRow>
    ));
  }, []);

  const renderSpeechAndCommunication = useMemo(() => {
    return SpeechAndCommunication.map((item: MotorSphereI, i: number) => (
      <TableRow key={`${item.name}_${i}`}>
        <TableCell>{ item.name }</TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.speech_communication_${i}_1`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.speech_communication_${i}_2`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.speech_communication_${i}_3`}
          />
        </TableCell>
      </TableRow>
    ));
  }, []);

  const renderSelfCareSkills = useMemo((): JSX.Element[] => {
    return SelfCareSkills.map((item, i): JSX.Element => (
      <TableRow key={`${item.name}_${i}`}>
        <TableCell>{ item.name }</TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.self_care_skills_${i}_1`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.self_care_skills_${i}_2`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.self_care_skills_${i}_3`}
          />
        </TableCell>
      </TableRow>
    ));
  }, []);

  const renderEar = useMemo((): JSX.Element[] => {
    return Ear.map((item, i): JSX.Element => (
      <TableRow key={`${item.name}_${i}`}>
        <TableCell>{ item.name }</TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.ear_${i}_1`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.ear_${i}_2`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.ear_${i}_3`}
          />
        </TableCell>
      </TableRow>
    ));
  }, []);

  const renderVision = useMemo((): JSX.Element[] => {
    return Vision.map((item, i): JSX.Element => (
      <TableRow key={`${item.name}_${i}`}>
        <TableCell>{ item.name }</TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.vision_${i}_1`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.vision_${i}_2`}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            className={styles.Input}
            name={`qb.vision_${i}_3`}
          />
        </TableCell>
      </TableRow>
    ));
  }, []);

  return (
    <Page
      classes={{
        root: styles.Root
      }}
    >
      <H1>Оценка развития ребенка раннего возраста</H1>
      <Paper className={styles.Paper}>
        <div className={styles.DateCompletion}>
          <DatePicker
            className={styles.Input}
            name="qb.date"
            label="Дата анкетирования"
            placeholder="Дата анкетирования"
            fullWidth
          />
          <Input
            className={styles.Input}
            name="qb.fio_specialist"
            label="Специалист по социальной работе"
            placeholder="Специалист по социальной работе"
            fullWidth
          />
        </div>
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>РАЗДЕЛ 1.  Общие сведения о ребенке</Subtitle>
        <div className={styles.SectionOne}>
          <Input
            className={styles.Input}
            name="qb.surname"
            label="Фамилия"
            placeholder="Фамилия"
          />
          <Input
            className={styles.Input}
            name="qb.name"
            label="Имя"
            placeholder="Имя"
          />
          <Input
            className={styles.Input}
            name="qb.patronymic"
            label="Отчество"
            placeholder="Отчество"
          />
          <Select
            className={styles.Input}
            name="qb.sex"
            label="Пол"
            placeholder="Пол"
          />
          <DatePicker
            className={styles.Input}
            name="qb.birthday"
            label="Дата рождения"
            placeholder="Дата рождения"
          />
        </div>
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>РАЗДЕЛ 2.  Информация о месте проживания ребенка</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>I обследование</TableCell>
              <TableCell>II обследование</TableCell>
              <TableCell>III обследование</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Адрес места проживания:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.address_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.address_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.address_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Кто заботится о ребенке:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.who_parent_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.who_parent_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.who_parent_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>С кем проживает ребенок:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.who_live_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.who_live_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.who_live_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>РАЗДЕЛ 3.  Особенности развития ребенка</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>I обследование</TableCell>
              <TableCell>II обследование</TableCell>
              <TableCell>III обследование</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width={200}>Вес ребенка:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.weight_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.weight_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.weight_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Рост ребенка:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.height_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.height_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.height_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Особенности физического состояния (опрелости, язвы, гематомы, малоактивный и т.п.)</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.physical_condition_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.physical_condition_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.physical_condition_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Особенности эмоционального состояния ребенка (как реагирует на происходящее вокруг него,
                плачет/не плачет, как ведет себя на руках у взрослого и т.п.):</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.emotional_condition_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.emotional_condition_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.emotional_condition_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Особенности поведения:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.behavior_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.behavior_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.behavior_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Получает ли ребенок медикаментозное лечение (если да, то какое):</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.medication_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.medication_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.medication_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Требует ли состояние ребенка особого ухода и заботы (если да, то какой):</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_condition_examination_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_condition_examination_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_condition_examination_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>
          (Данный раздел оценивается как минимум 3 раза: на момент взятия случая в работу, далее не ранее,
          чем через 3-х недели после начала работы с семьей, последний раз – перед закрытием случая.
          В графах «I, II и III обсл.» необходимо указать дату обследования и возраст ребенка на момент обследования.
          Оценка проводится по всем пунктам, начиная с первых месяцев, заканчивая актуальным возрастом ребенка.)
        </Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={400}>Двигательная сфера (отметить «+», если ребенок выполняет указанное действие)</TableCell>
              <TableCell>I обсл.</TableCell>
              <TableCell>II обсл.</TableCell>
              <TableCell>III обсл.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { renderMotorSphere }
          </TableBody>
        </Table>
        <Input
          className={styles.Input}
          name="qb.commentary_motor_sphere"
          label="Примечание"
          placeholder="Примечание"
          fullWidth
        />
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>
          (Данный раздел оценивается как минимум 3 раза: на момент взятия случая в работу, далее не ранее,
          чем через 3-х недели после начала работы с семьей, последний раз – перед закрытием случая.
          В графах «I, II и III обсл.» необходимо указать дату обследования и возраст ребенка на момент обследования.
          Оценка проводится по всем пунктам, начиная с первых месяцев, заканчивая актуальным возрастом ребенка.)
        </Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={400}>Речь, коммуникация (отметить «+», если ребенок выполняет указанное действие)</TableCell>
              <TableCell>I обсл.</TableCell>
              <TableCell>II обсл.</TableCell>
              <TableCell>III обсл.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { renderSpeechAndCommunication }
          </TableBody>
        </Table>
        <Input
          className={styles.Input}
          name="qb.commentary_speech_communication"
          label="Примечание"
          placeholder="Примечание"
          fullWidth
        />
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>
          (Данный раздел оценивается как минимум 3 раза: на момент взятия случая в работу, далее не ранее,
          чем через 3-х недели после начала работы с семьей, последний раз – перед закрытием случая.
          В графах «I, II и III обсл.» необходимо указать дату обследования и возраст ребенка на момент обследования.
          Оценка проводится по всем пунктам, начиная с первых месяцев, заканчивая актуальным возрастом ребенка.)
        </Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={400}>Навыки ухода за собой (отметить «+», если ребенок выполняет указанное действие)</TableCell>
              <TableCell>I обсл.</TableCell>
              <TableCell>II обсл.</TableCell>
              <TableCell>III обсл.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { renderSelfCareSkills }
          </TableBody>
        </Table>
        <Input
          className={styles.Input}
          name="qb.commentary_self_care_skills"
          label="Примечание"
          placeholder="Примечание"
          fullWidth
        />
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>
          (Данный раздел оценивается как минимум 3 раза: на момент взятия случая в работу, далее не ранее,
          чем через 3-х недели после начала работы с семьей, последний раз – перед закрытием случая.
          В графах «I, II и III обсл.» необходимо указать дату обследования и возраст ребенка на момент обследования.
          Оценка проводится по всем пунктам, начиная с первых месяцев, заканчивая актуальным возрастом ребенка.)
        </Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={400}>Слух (отметить «+», если ребенок выполняет указанное действие)</TableCell>
              <TableCell>I обсл.</TableCell>
              <TableCell>II обсл.</TableCell>
              <TableCell>III обсл.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { renderEar }
          </TableBody>
        </Table>
        <Input
          className={styles.Input}
          name="qb.commentary_ear"
          label="Примечание"
          placeholder="Примечание"
          fullWidth
        />
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle>
          (Данный раздел оценивается как минимум 3 раза: на момент взятия случая в работу, далее не ранее,
          чем через 3-х недели после начала работы с семьей, последний раз – перед закрытием случая.
          В графах «I, II и III обсл.» необходимо указать дату обследования и возраст ребенка на момент обследования.
          Оценка проводится по всем пунктам, начиная с первых месяцев, заканчивая актуальным возрастом ребенка.)
        </Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={400}>Зрение (отметить «+», если ребенок выполняет указанное действие)</TableCell>
              <TableCell>I обсл.</TableCell>
              <TableCell>II обсл.</TableCell>
              <TableCell>III обсл.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { renderVision }
          </TableBody>
        </Table>
        <Input
          className={styles.Input}
          name="qb.commentary_vision"
          label="Примечание"
          placeholder="Примечание"
          fullWidth
        />
      </Paper>
      <Paper className={styles.Paper}>
        <Subtitle className={styles.Subtitle}>РАЗДЕЛ 4.  Режим дня ребенка. Кормление ребенка:</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>I обследование</TableCell>
              <TableCell>II обследование</TableCell>
              <TableCell>III обследование</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell width={200}>Количество кормлений в сутки:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_day_regimen_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_day_regimen_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_day_regimen_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Какой перерыв между кормлениями:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_break_regimen_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_break_regimen_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_break_regimen_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Способ кормления:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.feeding_method_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.feeding_method_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.feeding_method_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Чем питается ребенок?</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_eat_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_eat_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_eat_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Введен ли прикорм?</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_lure_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_lure_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_lure_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={200}>Аллергия:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.allergy_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.allergy_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.allergy_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Subtitle className={styles.Subtitle}>Гигиенические процедуры (Особенности выполнения):</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>I обследование</TableCell>
              <TableCell>II обследование</TableCell>
              <TableCell>III обследование</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.hygiene_procedures_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.hygiene_procedures_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.hygiene_procedures_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Subtitle className={styles.Subtitle}>Сон:</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>I обследование</TableCell>
              <TableCell>II обследование</TableCell>
              <TableCell>III обследование</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Сколько раз в день спит:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_sleep_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_sleep_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_sleep_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Максимальная длительность бодрствования:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_duration_wakefulness_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_duration_wakefulness_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_duration_wakefulness_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Общая продолжительность сна в сутки:</TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_sleep_duration_1"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_sleep_duration_2"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
              <TableCell>
                <Input
                  className={styles.Input}
                  name="qb.child_sleep_duration_3"
                  multiline
                  rowsMax={3}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <H2>Приложение 1</H2>
      <Paper className={styles.Paper}>
        <Subtitle>Рост мальчиков от 1 года до 3-х лет (см)</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Возраст/Показатели</TableCell>
              <TableCell>очень низкий</TableCell>
              <TableCell>низкий</TableCell>
              <TableCell>ниже среднего</TableCell>
              <TableCell>средний</TableCell>
              <TableCell>выше среднего</TableCell>
              <TableCell>высокий</TableCell>
              <TableCell>очень высокий</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>12 мес</TableCell>
              <TableCell>&lt;	71,2</TableCell>
              <TableCell>71,2-72,3</TableCell>
              <TableCell>72,3-74,0</TableCell>
              <TableCell>74,0-77,3</TableCell>
              <TableCell>77,3-79,7</TableCell>
              <TableCell>79,7-81,7</TableCell>
              <TableCell>&gt;	81,7</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>15 мес</TableCell>
              <TableCell>&lt;	74,8</TableCell>
              <TableCell>74,8-75,9</TableCell>
              <TableCell>75,9-77,1</TableCell>
              <TableCell>77,1-81,0</TableCell>
              <TableCell>81,0-83,0</TableCell>
              <TableCell>83,0-85,3</TableCell>
              <TableCell>&gt;	85,3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>18 мес</TableCell>
              <TableCell>&lt;	76,9</TableCell>
              <TableCell>76,9-78,4</TableCell>
              <TableCell>78,4-79,8</TableCell>
              <TableCell>79,8-83,9</TableCell>
              <TableCell>83,9-85,9</TableCell>
              <TableCell>85,9-89,4</TableCell>
              <TableCell>&gt;	89,4</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21 мес</TableCell>
              <TableCell>&lt;	79,3</TableCell>
              <TableCell>79,3-80,3</TableCell>
              <TableCell>80,3-82,3</TableCell>
              <TableCell>82,3-86,5</TableCell>
              <TableCell>86,5-88,3</TableCell>
              <TableCell>88,3-91,2</TableCell>
              <TableCell>&gt;	91,2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24 мес</TableCell>
              <TableCell>&lt;	81,3</TableCell>
              <TableCell>81,3-83,0</TableCell>
              <TableCell>83,0-84,5</TableCell>
              <TableCell>84,5-89,0</TableCell>
              <TableCell>89,0-90,8</TableCell>
              <TableCell>90,8-94,0</TableCell>
              <TableCell>&gt;	94,0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27 мес</TableCell>
              <TableCell>&lt;	83,0</TableCell>
              <TableCell>83,0-84,9</TableCell>
              <TableCell>84,9-86,1</TableCell>
              <TableCell>86,1-91,3</TableCell>
              <TableCell>91,3-93,9</TableCell>
              <TableCell>93,9-96,8</TableCell>
              <TableCell>&gt;	96,8</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30 мес</TableCell>
              <TableCell>&lt;	84,5</TableCell>
              <TableCell>84,5-87,0</TableCell>
              <TableCell>87,0-89,0</TableCell>
              <TableCell>89,0-93,7</TableCell>
              <TableCell>93,7-95,5</TableCell>
              <TableCell>95,5-99,0</TableCell>
              <TableCell>&gt;	99,0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>33 мес</TableCell>
              <TableCell>&lt;	86,3</TableCell>
              <TableCell>86,3-88,8</TableCell>
              <TableCell>88,8-91,3</TableCell>
              <TableCell>91,3-96,0</TableCell>
              <TableCell>96,0-98,1</TableCell>
              <TableCell>98,1-101,2</TableCell>
              <TableCell>&gt;	101,2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3 года</TableCell>
              <TableCell>&lt;	88,0</TableCell>
              <TableCell>88,0-90,0</TableCell>
              <TableCell>90,0-92,3</TableCell>
              <TableCell>92,3-99,8</TableCell>
              <TableCell>99,8-102,0</TableCell>
              <TableCell>102,0-104,5</TableCell>
              <TableCell>&gt;	104,5</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Subtitle className={styles.Subtitle}>Рост девочек от 1 года до 3-х лет (см)</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Возраст/Показатели</TableCell>
              <TableCell>очень низкий</TableCell>
              <TableCell>низкий</TableCell>
              <TableCell>ниже среднего</TableCell>
              <TableCell>средний</TableCell>
              <TableCell>выше среднего</TableCell>
              <TableCell>высокий</TableCell>
              <TableCell>очень высокий</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>12 мес</TableCell>
              <TableCell>&lt;	70,1</TableCell>
              <TableCell>70,1-71,4</TableCell>
              <TableCell>71,4-72,8</TableCell>
              <TableCell>72,8-75,8</TableCell>
              <TableCell>75,8-78,0</TableCell>
              <TableCell>78,0-79,6</TableCell>
              <TableCell>&gt;	79,6</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>15 мес</TableCell>
              <TableCell>&lt;	72,9</TableCell>
              <TableCell>72,9-74,5</TableCell>
              <TableCell>74,5-76,0</TableCell>
              <TableCell>76,0-79,1</TableCell>
              <TableCell>79,1-81,5</TableCell>
              <TableCell>81,5-83,4</TableCell>
              <TableCell>&gt;	83,4</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>18 мес</TableCell>
              <TableCell>&lt;	75,8</TableCell>
              <TableCell>75,8-77,1</TableCell>
              <TableCell>77,1-78,9</TableCell>
              <TableCell>78,9-82,1</TableCell>
              <TableCell>82,1-84,5</TableCell>
              <TableCell>84,5-86,8</TableCell>
              <TableCell>&gt;	86,8</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21 мес</TableCell>
              <TableCell>&lt;	78,0</TableCell>
              <TableCell>78,0-79,5</TableCell>
              <TableCell>79,5-81,2</TableCell>
              <TableCell>81,2-84,5</TableCell>
              <TableCell>84,5-87,5</TableCell>
              <TableCell>87,5-89,5</TableCell>
              <TableCell>&gt;	89,5</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24 мес</TableCell>
              <TableCell>&lt;	80,1</TableCell>
              <TableCell>80,1-81,7</TableCell>
              <TableCell>81,7-83,3</TableCell>
              <TableCell>83,3-87,5</TableCell>
              <TableCell>87,5-90,1</TableCell>
              <TableCell>90,1-92,5</TableCell>
              <TableCell>&gt;	92,5</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27 мес</TableCell>
              <TableCell>&lt;	82,0</TableCell>
              <TableCell>82,0-83,5</TableCell>
              <TableCell>83,5-85,4</TableCell>
              <TableCell>85,4-90,1</TableCell>
              <TableCell>90,1-92,4</TableCell>
              <TableCell>92,4-95,0</TableCell>
              <TableCell>&gt;	95,0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30 мес</TableCell>
              <TableCell>&lt;	83,8</TableCell>
              <TableCell>83,8-85,7</TableCell>
              <TableCell>85,7-87,7</TableCell>
              <TableCell>87,7-92,3</TableCell>
              <TableCell>92,3-95,0</TableCell>
              <TableCell>95,0-97,3</TableCell>
              <TableCell>&gt;	97,3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>33 мес</TableCell>
              <TableCell>&lt;	85,8</TableCell>
              <TableCell>85,8-87,6</TableCell>
              <TableCell>87,6-89,8</TableCell>
              <TableCell>89,8-94,8</TableCell>
              <TableCell>94,8-97,0</TableCell>
              <TableCell>97,0-99,7</TableCell>
              <TableCell>&gt;	99,7</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3 года</TableCell>
              <TableCell>&lt;	89,0</TableCell>
              <TableCell>89,0-90,8</TableCell>
              <TableCell>90,8-93,0</TableCell>
              <TableCell>93,0-98,1</TableCell>
              <TableCell>98,1-100,7</TableCell>
              <TableCell>100,7-103,1</TableCell>
              <TableCell>&gt;	103,1</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <H2>Приложение 2</H2>
      <Paper className={styles.Paper}>
        <Subtitle>Вес мальчиков от 1 года до 3-х лет( кг )</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Возраст/Показатели</TableCell>
              <TableCell>очень низкий</TableCell>
              <TableCell>низкий</TableCell>
              <TableCell>ниже среднего</TableCell>
              <TableCell>средний</TableCell>
              <TableCell>выше среднего</TableCell>
              <TableCell>высокий</TableCell>
              <TableCell>очень высокий</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>12 мес</TableCell>
              <TableCell>&lt;	8,5</TableCell>
              <TableCell>8,5-8,9</TableCell>
              <TableCell>8,9-9,4</TableCell>
              <TableCell>9,4-10,9</TableCell>
              <TableCell>10,9-11,6</TableCell>
              <TableCell>11,6-12,1</TableCell>
              <TableCell>&gt;	12,1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>15 мес</TableCell>
              <TableCell>&lt;	9,2</TableCell>
              <TableCell>9,2-9,6</TableCell>
              <TableCell>9,6-10,1</TableCell>
              <TableCell>10,1-11,7</TableCell>
              <TableCell>11,7-12,4</TableCell>
              <TableCell>12,4-13,0</TableCell>
              <TableCell>&gt;	13,0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>18 мес</TableCell>
              <TableCell>&lt;	9,7</TableCell>
              <TableCell>9,7-10,2</TableCell>
              <TableCell>10,2-10,7</TableCell>
              <TableCell>10,7-12,4</TableCell>
              <TableCell>12,4-13,0</TableCell>
              <TableCell>13,0-13,7</TableCell>
              <TableCell>&gt;	13,7</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21 мес</TableCell>
              <TableCell>&lt;	10,2</TableCell>
              <TableCell>10,2-10,6</TableCell>
              <TableCell>10,6-11,2</TableCell>
              <TableCell>11,2-12,9</TableCell>
              <TableCell>12,9-13,6</TableCell>
              <TableCell>13,6-14,3</TableCell>
              <TableCell>&gt;	14,3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24 мес</TableCell>
              <TableCell>&lt;	10,6</TableCell>
              <TableCell>10,6-11,0</TableCell>
              <TableCell>11,0-11,7</TableCell>
              <TableCell>11,7-13,5</TableCell>
              <TableCell>13,5-14,2</TableCell>
              <TableCell>14,2-15,0</TableCell>
              <TableCell>&gt;	15,0</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27 мес</TableCell>
              <TableCell>&lt;	11,0</TableCell>
              <TableCell>11,0-11,5</TableCell>
              <TableCell>11,5-12,2</TableCell>
              <TableCell>12,2-14,1</TableCell>
              <TableCell>14,1-14,8</TableCell>
              <TableCell>14,8-15,6</TableCell>
              <TableCell>&gt;	15,6</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30 мес</TableCell>
              <TableCell>&lt;	11,4</TableCell>
              <TableCell>11,4-11,9</TableCell>
              <TableCell>11,9-12,6</TableCell>
              <TableCell>12,6-14,6</TableCell>
              <TableCell>14,6-15,4</TableCell>
              <TableCell>15,4-16,1</TableCell>
              <TableCell>&gt;	16,1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>33 мес</TableCell>
              <TableCell>&lt;	11,6</TableCell>
              <TableCell>11,6-12,3</TableCell>
              <TableCell>12,3-13,1</TableCell>
              <TableCell>13,1-15,2</TableCell>
              <TableCell>15,2-16,0</TableCell>
              <TableCell>16,0-16,8</TableCell>
              <TableCell>&gt;	16,8</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3 года</TableCell>
              <TableCell>&lt;	12,1</TableCell>
              <TableCell>12,1-12,8</TableCell>
              <TableCell>12,8-13,8</TableCell>
              <TableCell>13,8-16,0</TableCell>
              <TableCell>16,0-16,9</TableCell>
              <TableCell>16,9-17,7</TableCell>
              <TableCell>&gt;	17,7</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Subtitle className={styles.Subtitle}>Вес девочек от 1 года до 3-х лет (кг)</Subtitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Возраст/Показатели</TableCell>
              <TableCell>очень низкий</TableCell>
              <TableCell>низкий</TableCell>
              <TableCell>ниже среднего</TableCell>
              <TableCell>средний</TableCell>
              <TableCell>выше среднего</TableCell>
              <TableCell>высокий</TableCell>
              <TableCell>очень высокий</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>12 мес</TableCell>
              <TableCell>&lt;	8,0</TableCell>
              <TableCell>8,0-8,5</TableCell>
              <TableCell>8,5-9,0</TableCell>
              <TableCell>9,0-10,2</TableCell>
              <TableCell>10,2-10,8</TableCell>
              <TableCell>10,8-11,3</TableCell>
              <TableCell>&gt;	11,3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>15 мес</TableCell>
              <TableCell>&lt;	8,6</TableCell>
              <TableCell>8,6-9,2</TableCell>
              <TableCell>9,2-9,7</TableCell>
              <TableCell>9,7-10,9</TableCell>
              <TableCell>10,9-11,5</TableCell>
              <TableCell>11,5-12,1</TableCell>
              <TableCell>&gt;	12,1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>18 мес</TableCell>
              <TableCell>&lt;	9,0</TableCell>
              <TableCell>9,0-9,8</TableCell>
              <TableCell>9,8-10,3</TableCell>
              <TableCell>10,3-11,5</TableCell>
              <TableCell>11,5-12,2</TableCell>
              <TableCell>12,2-12,8</TableCell>
              <TableCell>&gt;	12,8</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>21 мес</TableCell>
              <TableCell>&lt;	9,7</TableCell>
              <TableCell>9,7-10,3</TableCell>
              <TableCell>10,3-10,6</TableCell>
              <TableCell>10,6-12,2</TableCell>
              <TableCell>12,2-12,8</TableCell>
              <TableCell>12,8-13,4</TableCell>
              <TableCell>&gt;	13,4</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>24 мес</TableCell>
              <TableCell>&lt;	10,2</TableCell>
              <TableCell>10,2-10,8</TableCell>
              <TableCell>10,8-11,3</TableCell>
              <TableCell>11,3-12,8</TableCell>
              <TableCell>12,8-13,5</TableCell>
              <TableCell>13,5-14,1</TableCell>
              <TableCell>&gt;	14,1</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>27 мес</TableCell>
              <TableCell>&lt;	10,6</TableCell>
              <TableCell>10,6-11,2</TableCell>
              <TableCell>11,2-11,7</TableCell>
              <TableCell>11,7-13,3</TableCell>
              <TableCell>13,3-14,2</TableCell>
              <TableCell>14,2-14,8</TableCell>
              <TableCell>&gt;	14,8</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>30 мес</TableCell>
              <TableCell>&lt;	11,0</TableCell>
              <TableCell>11,0-11,6</TableCell>
              <TableCell>11,6-12,3</TableCell>
              <TableCell>12,3-13,9</TableCell>
              <TableCell>13,9-14,8</TableCell>
              <TableCell>14,8-15,5</TableCell>
              <TableCell>&gt;	15,5</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>33 мес</TableCell>
              <TableCell>&lt;	11,5</TableCell>
              <TableCell>11,5-12,1</TableCell>
              <TableCell>12,1-12,7</TableCell>
              <TableCell>12,7-14,5</TableCell>
              <TableCell>14,5-15,4</TableCell>
              <TableCell>15,4-16,3</TableCell>
              <TableCell>&gt;	16,3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3 года</TableCell>
              <TableCell>&lt;	11,7</TableCell>
              <TableCell>11,7-12,5</TableCell>
              <TableCell>12,5-13,3</TableCell>
              <TableCell>13,3-15,5</TableCell>
              <TableCell>15,5-16,5</TableCell>
              <TableCell>16,5-17,6</TableCell>
              <TableCell>&gt;	17,6</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Page>
  )
};

export default QB;
