// @flow
import { PURGE } from 'redux-persist';

// Types
import { ADD_OPTIONS, ACTION_TYPE } from './types';

// Data
import INITIAL_STATE from './initial';

export const reducer = (state: {} = INITIAL_STATE, action: ACTION_TYPE) => {
  switch (action.type) {
    case ADD_OPTIONS:
      return {
        ...state,
        ...action.payload,
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
