// Libraries
import React from "react";
import {get} from "lodash";
import {compose} from "recompose";
import {connect} from "react-redux";

// Components
import {Page} from "components/Page";
import Title from "./components/Title";
import Wizard from "./components/Wizard/Wizard";

// Services
import { SESSION_TYPE, ACTION_TYPE } from 'services/session';

// Hooks
import {useFamily, FamilyContext} from "./hooks";

// Styles
import styles from './Family.module.scss';

// Data
import {PATHS_MAP} from "./data";

type FAMILIES_PROPS_TYPE = {
  session?: SESSION_TYPE,
  match: {
    url: string,
    path: string,
  }
};

const Family = ({ session, match }: FAMILIES_PROPS_TYPE): JSX.Element => {
  const { path } = match;
  const { role, title } = get(PATHS_MAP, `['${path.split('/:id')[0]}']`, PATHS_MAP['/families']);
  const usedFamily = useFamily({ xyi: 123 });

  const { loading } = usedFamily;

  return (
    <Page loading={loading}>
      <div className={styles.Wrapper}>
        <FamilyContext.Provider value={usedFamily}>
          <Title className={styles.Title} />
          <div className={styles.Actions}>
            <Wizard />
          </div>
        </FamilyContext.Provider>
      </div>
    </Page>
  )
};

// Exports
const mapStateToProps = (state: object): object => ({
  session: get(state, 'session')
});

const mapDispatchToProps = (dispatch: (action: ACTION_TYPE) => any) => ({
  // Services
});

// Exports
export default compose<FAMILIES_PROPS_TYPE, any>(connect(mapStateToProps, mapDispatchToProps))(Family);
