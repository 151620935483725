// Libraries
import React from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";

// Components
import Page from "components/Page";
import { H1 } from "components/Typography/Typography";
import Paper from "@material-ui/core/Paper";
import {Checkbox, DatePicker, Input, Select} from "components/Form";
import {Button} from "components/Button/Button";

// Styles
import styles from "./styles.module.scss";

const OtherPerson = (): JSX.Element => {
  return (
    <Page classes={{
      root: styles.Root
    }}>
      <H1 className={styles.Title}>Иное лицо</H1>
      <Paper className={styles.Paper}>
        <div className={styles.Row}>
          <Input
            className={classNames(styles.Input, styles.InputHalfWith)}
            name="others.fio"
            label="ФИО"
            placeholder="ФИО"
          />
          <Input
            className={classNames(styles.Input, styles.InputHalfWith)}
            name="others.phone"
            label="Телефон"
            placeholder="Телефон"
          />
        </div>
        <Input
          className={styles.Input}
          name="others.address_fact"
          label="Адрес фактический"
          placeholder="Адрес фактический"
          fullWidth
        />
        <div className={styles.Row}>
          <Input
            className={classNames(styles.Input, styles.InputHalfWith)}
            name="others.passport_series"
            label="Серия паспорта"
            placeholder="Серия паспорта"
          />
          <Input
            className={classNames(styles.Input, styles.InputHalfWith)}
            name="others.passport_number"
            label="Номер паспорта"
            placeholder="Номер паспорта"
          />
        </div>
        <Input
          className={styles.Input}
          name="others.address_reg"
          label="Адрес регистрации"
          placeholder="Адрес регистрации"
          fullWidth
        />
        <Input
          className={styles.Input}
          name="others.who_give"
          label="Кем выдан"
          placeholder="Кем выдан"
          fullWidth
        />
        <div className={styles.Row}>
          <DatePicker
            className={classNames(styles.Input, styles.InputHalfWith)}
            name="others.date_give"
            label="Дата выдачи"
            placeholder="Дата выдачи"
          />
          <Input
            className={classNames(styles.Input, styles.InputHalfWith)}
            name="others.code"
            label="Код подразделения"
            placeholder="Код подразделения"
          />
        </div>
        <Select
          className={styles.Input}
          name="others.program"
          label="Программа"
          placeholder="Программа"
          fullWidth
        />
        <div className={styles.Row}>
          <DatePicker
            className={classNames(styles.Input, styles.InputHalfWith)}
            name="others.date_going"
            label="Дата прохождения"
            placeholder="Дата прохождения"
          />
          <Checkbox
            className={styles.Input}
            name="others.training_is_completed"
            label="Обучение пройдено"
          />
        </div>
        <div className={styles.Row}>
          <Button
            classes={{ root: styles.Btn }}
            component={Link}
            href={`/other-people`}
          >Сохранить
          </Button>
          <Button
            classes={{ root: styles.Btn }}
            component={Link}
            href={`/other-people`}
          >Удалить
          </Button>
        </div>
      </Paper>
    </Page>
  )
};

export default OtherPerson;
