// Libraries
import React from "react";
import {FieldArray} from "redux-form";
import {useHistory, useParams} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

// Components
import {Button} from "components/Button/Button";
import { Paper } from "@material-ui/core";
import {Select} from "components/Form";
import Assignment from '@material-ui/icons/Assignment'

// Styles
import styles from './styles.module.scss';

type Params = {
  userId: string;
}

const Contracts = (): JSX.Element => {
  const history = useHistory();
  const { userId }: Params = useParams();

  const renderChildren = ({ fields, meta: { error } }: any): JSX.Element => (
    <>
      {
        fields.map((child: any, i: number): JSX.Element => (
          <div className={styles.ChildContainer} key={i}>
            <Select
              className={styles.Input}
              name="thing.member"
              label="Член семьи"
              placeholder="Член семьи"
            />
            <Button
              classes={{ root: styles.ActionsBtn }}
              onClick={() => fields.remove(i)}
            >-
            </Button>
          </div>
        ))
      }
      <Button
        classes={{ root: styles.ActionsBtn }}
        onClick={() => fields.push()}
      >Добавить ребёнка
      </Button>
    </>
  );

  const renderOtherSides = ({ fields, meta: { error } }: any): JSX.Element => (
    <>
      {
        fields.map((side: any, i: number) => (
          <div className={styles.ChildContainer} key={i}>
            <Select
              className={styles.Input}
              name={`${side}.other_side`}
              label="Иное лицо"
              placeholder="Иное лицо"
            />
            <Button
              classes={{ root: styles.ActionsBtn }}
              onClick={() => fields.remove(i)}
            >-
            </Button>
          </div>
        ))
      }
      <Button
        classes={{ root: styles.ActionsAddOtherSideBtn }}
        onClick={() => fields.push()}
      >Добавить иное лицо
      </Button>
    </>
  );

  return (
    <div>
      <p>Договоры</p>
      <Paper className={styles.Paper}>
        <div>
          <p>Член семьи</p>
          <Select
            className={styles.Input}
            name="member"
            label="Член семьи"
            placeholder="Член семьи"
          />
        </div>
        <div>
          <p>Дети</p>
          <FieldArray name='children' component={renderChildren} />
        </div>
      </Paper>
      <br/>
      <p>3-я сторона</p>
      <Paper className={styles.Paper}>
        <FieldArray name='other_sides' component={renderOtherSides} />
        <div className={styles.Agreements}>
          <div className={styles.AgreementsContainer}>
            <Assignment />
            <a href="http://localhost:3000/">Договор оценки</a>
          </div>
          <div className={styles.AgreementsContainer}>
            <Assignment />
            <a href="http://localhost:3000/">Договор соправождения</a>
          </div>
          <div className={styles.AgreementsContainer}>
            <Assignment />
            <a href="http://localhost:3000/">Оказание соц.услуг</a>
          </div>
          <div className={styles.AgreementsContainer}>
            <Assignment />
            <a href="http://localhost:3000/">3-х сторонний договор</a>
          </div>
          <div className={styles.AgreementsContainer}>
            <Assignment />
            <a href="http://localhost:3000/">Акт передачи ребёнка</a>
          </div>
          <div className={styles.AgreementsContainer}>
            <Assignment />
            <a href="http://localhost:3000/">Акт о социальных услугах</a>
          </div>
        </div>
      </Paper>

      <div className={styles.Actions}>
        <Button
          classes={{ root: styles.ActionsBtn }}
          type="submit"
        >Сохранить
        </Button>
        <Button
          classes={{ root: styles.ActionsBtn }}
        >Печать
        </Button>
        <Button
          classes={{ root: styles.ActionsBtn }}
          onClick={() => history.push(`/things/${userId}/new`)}
        >Отмена
        </Button>
      </div>
    </div>
  )
};

const CONTRACTS = {
  id: uuidv4(),
  name: 'Договоры',
  data: Contracts
}

export default CONTRACTS;
