import { useEffect, useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useParams } from "react-router-dom";
import validator from 'validator';
import { get, replace, capitalize, join, toLower } from 'lodash';
import { loader } from 'graphql.macro';
import moment from 'moment';

// Hooks
import { ThingsContext } from '../../../../hooks';

// Config
import { SERVER_ERRORS_HANDLER, ERROR_CONNECTION, MESSAGES_MAP } from 'config';

// Types
import { SIGN_IN_TYPE, SESSION_TYPE } from 'services/session';
import { OPTION_TYPE } from 'services/options';

// GraphQL
const profileMutation = loader('src/graphql/mutations/profile.gql');

type USE_STEP_PROPS_TYPE = {
  session?: SESSION_TYPE,
  formValues?: object,
  signIn: SIGN_IN_TYPE,
  roles?: [OPTION_TYPE],
  reset: () => void,
  initialize: (data: object) => void,
};

export const useStep = ({
  formValues,
  initialize,
}: USE_STEP_PROPS_TYPE) => {
  const { id }: { id: string } = useParams();
  const isNew = toLower(id) === 'new';

  const [ editableData ] = useState<object>();

  const {
    loading,
    step,
    values,
    finished,
    limit,
    error,
    fail,
    handleValues,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    role,
  } = useContext(ThingsContext);

  const [
    handleProfileMutation,
    profileMutationResult
  ] = useMutation(
    profileMutation,
    {
      fetchPolicy: 'no-cache',
      onError: (error: any) => SERVER_ERRORS_HANDLER(error, (error?: any) => handleFail(!!error))
    }
  );

  const { data: profileMutationData, error: profileMutationError } = profileMutationResult;
  
  useEffect(() => {
    editableData && initialize({
      ...editableData
    });
  }, [editableData]);

  useEffect(() => {
    if (!profileMutationData || loading) {
      return;
    }
    const isError = get(profileMutationData, 'profile.__typename') === 'Error';
    const message = get(profileMutationData, 'profile.message');

    const dataError = isError && get(MESSAGES_MAP, `${message}`, message);
    const currentErrors = [
      ...(profileMutationError ? [ERROR_CONNECTION] : []),
      ...(dataError ? [dataError] : []),
    ];
    currentErrors.length <= 0
      ? handleNext({ errors: false })
      : handleError(join(currentErrors, '\n'));
  }, [loading, profileMutationData, profileMutationError]);

  const handleSubmit = () => {
    // get(event, 'preventDefault') && event.preventDefault();
    const login = replace(
      get(formValues, 'login', get(values, 'login')),
      / /g,
      ''
    );
    const isPhone =
      login && validator.isMobilePhone(login, 'ru-RU', { strictMode: false });
    const name = capitalize(get(formValues, 'name', get(values, 'name', '')));
    const surname = capitalize(get(formValues, 'surname', get(values, 'surname', '')));
    const patronymic = capitalize(get(formValues, 'patronymic', get(values, 'patronymic', '')));
    const birthday = get(formValues, 'birthday', get(values, 'birthday', ''));
    const gender = get(formValues, 'gender', get(values, 'gender', ''));
    const password = get(formValues, 'password', '');
    const confirmation = get(formValues, 'confirmation', '');
    const role = get(formValues, 'role.value', get(values, 'role.value', ''));
    const blocked = get(formValues, 'blocked', get(values, 'blocked', false));

    const additional_phone = capitalize(get(formValues, 'additional_phone', get(values, 'additional_phone', '')));
    const position = capitalize(get(formValues, 'position', get(values, 'position', '')));
    const address = capitalize(get(formValues, 'address', get(values, 'address', '')));
    
    const isNewLogin = login !== get(editableData, 'email') && login !== get(editableData, 'phone');
    const stepValues = {
      ...(isNew ? { new: true } : { id }),
      ...(isNewLogin && {[ isPhone ? 'phone' : 'email']: login}),
      name,
      surname,
      patronymic,
      birthday: birthday && moment(birthday, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      gender,
      password,
      confirmation,
      role,
      blocked,
      additional_phone,
      position,
      address
    };
    handleValues({
      ...stepValues,
    });
    return handleProfileMutation({ variables: { ...stepValues } });
  };

  return {
    loading,
    step,
    finished,
    limit,
    error,
    fail,
    handleBack,
    handleNext,
    handleError,
    handleFail,
    handleLoading,
    handleSubmit,
    role,
  };
};

export default useStep;
